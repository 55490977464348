import { styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material/LinearProgress";

export interface BorderLinearProgressProps extends LinearProgressProps {
  height?: number;
  borderRadius?: number;
}

export const BorderLinearProgress = styled(
  LinearProgress
)<BorderLinearProgressProps>(({ theme, height = 20, borderRadius = 5 }) => ({
  height,
  borderRadius,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));
