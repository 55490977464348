import { Link, Typography } from "@mui/material";
import React from "react";
import config from "src/config";

const AboutPage = () => {
  return (
    <>
      <Typography variant="h4" sx={{ mb: 1, mt: 1 }}>
        Про проєкт
      </Typography>
      <Typography variant="body1">
        "Передай Далі" - це платформа, створена для збору коштів на потреби
        українських військових, які захищають нашу країну. 🇺🇦❤️
      </Typography>
      <Typography variant="body1">
        Наші військові постійно потребують транспорту та технологічних
        пристроїв, які знищують ворога, але також і знищуються ворогом. За
        допомогою "Передай Далі" кожен з нас може допомогти українським
        захисникам, надіславши кошти на потрібні проєкти.
      </Typography>
      <Typography variant="h5" sx={{ mb: 1, mt: 3 }}>
        Що дозволяє "Передай Далі"?
      </Typography>
      <Typography variant="body1">
        Платформа дозволяє переглянути історію роботи кожного волонтера, що
        підвищує рівень довіри та збільшує кількість донатів.
      </Typography>
      <Typography variant="body1">
        Волонтери можуть вести облік зборів, що організовують, це робить процес
        збору більш прозорим. До кожного збору додано опис та реквізити для
        надсилання коштів. Волонтери можуть публікувати оновлення, що стосуються
        зборів щоб тримати в курсі людей, що їх підтримують. По завершенню
        зборів волонтери можуть опублікувати звіти про виконану роботу.
      </Typography>
      <Typography variant="body1">
        Користувачі платформи можуть демонструвати свою довіру до волонтерів
        додаючи відгуки на їх сторінках. В свою чергу інші користувачі можуть
        оцінювати волонтерів за даними відгуками.
      </Typography>
      <Typography variant="h5" sx={{ mb: 1, mt: 3 }}>
        Як це працює?
      </Typography>
      <Typography variant="body1">
        На даний момент проєкт знаходиться на стадії розробки, тому інформація
        про збори додається розробниками платформи після перевірки.
      </Typography>
      <Typography variant="body1">
        Інформація про стан рахунків типу "банка від Монобанк" оновлюється
        щогодини. Інформація про стан рахунків інших типів оновлюється у ручному
        режимі на запит волонтерів.
      </Typography>
      <Typography variant="h5" sx={{ mb: 1, mt: 3 }}>
        Як додати мій збір на платформу?
      </Typography>
      <Typography variant="body1">
        Для подання заявки на додавання вашого збору на сайт зверніться до
        розробників за допомогою функції{" "}
        <Link href={config.feedbackAccountUrl} target="_blank" variant="body1">
          "Зворотний звʼязок"
        </Link>
        <br />
        Необхідна інформація:
      </Typography>
      <ol style={{ marginTop: 0, paddingLeft: 20 }}>
        <li>Назва (заголовок) збору</li>
        <li>Детальний опис збору</li>
        <li>Посилання на ваші соціальні мережі</li>
        <li>Необхідна сума</li>
        <li>Реквізити ваших рахунків</li>
      </ol>

      <Typography variant="h5" sx={{ mb: 1, mt: 3 }}>
        Історія створення
      </Typography>

      <Typography variant="body1">
        Проєкт був започаткований Дмитром Карповичем у лютому 2023 року. До
        створення вебсайту "облік" волонтерських зборів проводився в{" "}
        <Link
          href="https://plucky-pamphlet-a82.notion.site/4657948abc0c49a6b30e2a716deb3d5a"
          target="_blank"
          variant="body1"
        >
          Notion документі
        </Link>
        . В цьому документі розміщувалися актуальні збори, які організовували
        учасники{" "}
        <Link
          href="https://t.me/+ZYUS8vL9MaY4NjBi"
          target="_blank"
          variant="body1"
        >
          спільноти ІТ-спеціалістів Хмельницького
        </Link>
        .
      </Typography>

      <Typography variant="h5" sx={{ mb: 1, mt: 3 }}>
        Історія змін
      </Typography>
      <Typography variant="body1">
        06.05.2023 - Додана можливість встановлювати фото профілю
      </Typography>
      <Typography variant="body1">
        28.04.2023 - Додана можливість для волонтерів публікувати оновлення на
        сторінках зборів
      </Typography>
      <Typography variant="body1">
        11.04.2023 - Додана можливість публікувати відгуки про волонтерів
      </Typography>
      <Typography variant="body1">
        25.03.2023 - Додана можливість авторизації за допомогою телеграм
      </Typography>
      <Typography variant="body1">
        15.03.2023 - Доданий функціонал тегів
      </Typography>
      <Typography variant="body1">
        11.03.2023 - Опублікована перша версія платформи
      </Typography>
    </>
  );
};

export default AboutPage;
