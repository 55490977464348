import React from "react";
import { Box, Card, CardHeader } from "@mui/material";

type BoxCardProps = {
  title: string;
  children: React.ReactNode;
};

const BoxCard = ({ title, children }: BoxCardProps) => {
  return (
    <Box sx={{ my: 4 }}>
      <Card sx={{ maxWidth: 400, margin: "auto" }}>
        <CardHeader title={title} sx={{ pb: 1 }} />
        {children}
      </Card>
    </Box>
  );
};

export default BoxCard;
