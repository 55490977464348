import React from "react";
import { Box, Link, Typography } from "@mui/material";
import config from "src/config";

export const Footer = () => {
  return (
    <Box sx={{ py: "1rem", display: "flex", justifyContent: "space-between" }}>
      <Typography
        style={{ opacity: 0.25 }}
        variant="body2"
        color="textSecondary"
      >
        {"© "}
        {new Date().getFullYear()}
        {" NullReferenceException"}
      </Typography>
      <Link href={config.feedbackAccountUrl} target="_blank" variant="body2">
        Зворотний звʼязок
      </Link>
    </Box>
  );
};
