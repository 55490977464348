import { Typography } from "@mui/material";
import React from "react";
import { ProjectsGrid } from "../components/projects/ProjectsGrid";
import { useProjects } from "../hooks/useProjects";

const HomePage = () => {
  const projects = useProjects({});
  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1 }}>
        Відкриті збори
      </Typography>
      <ProjectsGrid projects={projects}></ProjectsGrid>
    </>
  );
};
export default HomePage;
