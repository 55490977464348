import { Alert, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { useUser } from "../../hooks/useUser";

const UpdateProfileAlert = () => {
  const user = useUser();

  return (
    <>
      {user && !user.roles.includes("user") && (
        <Alert variant="outlined" severity="warning" sx={{ mt: 1 }}>
          Будь ласка, заповніть ваш{" "}
          <Link to={"/profile/edit"} component={RouterLink}>
            <strong>профіль</strong>
          </Link>{" "}
          для повноцінного використання платформою.
        </Alert>
      )}
    </>
  );
};

export default UpdateProfileAlert;
