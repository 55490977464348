import React from "react";
import { Box, Link, Typography } from "@mui/material";
import config from "src/config";

const ProjectCreateRequest = () => {
  return (
    <Box>
      <Typography variant="h4" sx={{ marginTop: 1, marginBottom: 1 }}>
        Заявка на публікацію збору
      </Typography>

      <Typography variant="body1">
        На даний момент проект знаходиться на стадії розробки, тому інформація
        про збори додається розробниками платформи після перевірки.
      </Typography>
      <Typography variant="body1">Необхідна інформація:</Typography>
      <ol style={{ margin: 0, paddingLeft: 20 }}>
        <li>Назва (заголовок) збору</li>
        <li>Детальний опис збору</li>
        <li>Посилання на ваші соціальні мережі</li>
        <li>Необхідна сума</li>
        <li>Реквізити ваших рахунків</li>
      </ol>
      <Typography variant="body1">
        Для подання заявки на додавання вашого збору, будь ласка, використовуйте
        функцію{" "}
        <Link href={config.feedbackAccountUrl} target="_blank" variant="body1">
          "Зворотний звʼязок"
        </Link>
      </Typography>
    </Box>
  );
};

export default ProjectCreateRequest;
