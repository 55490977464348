import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { AccountModel, AccountUpdate, AccountUpdateModel } from "../../types";
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useEffect, useState } from "react";
import { AccountUpdateRepository } from "../../api/repositories";
import { AccountUpdateList } from "./AccoutUpdateList";
import { AccountUpdateForm } from "./AccountUpdateForm";

interface AccountUpdatesDialogProps {
  open: boolean;
  onClose: () => void;
  account: AccountModel;
}

const AccountUpdatesDialog = ({
  open,
  onClose,
  account,
}: AccountUpdatesDialogProps) => {
  const repository = new AccountUpdateRepository(account.id);

  const [updates, setUpdates] = useState<AccountUpdateModel[]>([]);

  const [editingAccountUpdate, setEditingAccountUpdate] =
    useState<AccountUpdate | null>(null);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const fetchUpdates = useCallback(async () => {
    const data = await repository.fetchAll();
    setUpdates(data as AccountUpdateModel[]);
  }, [account]);

  useEffect(() => {
    fetchUpdates();
  }, [fetchUpdates, account]);

  function hideAccountUpdateForm() {
    setEditingAccountUpdate(null);
    setIsFormOpen(false);
  }

  const handleAccountUpdateSubmit = async (accountUpdate: AccountUpdate) => {
    try {
      await repository.createOrUpdate(accountUpdate);
      hideAccountUpdateForm();
      await fetchUpdates();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditAccountUpdate = (accountUpdate: AccountUpdateModel) => {
    setEditingAccountUpdate(accountUpdate);
    setIsFormOpen(true);
  };

  const handleDeleteAccountUpdate = async (
    accountUpdate: AccountUpdateModel
  ) => {
    try {
      await repository.deleteById(accountUpdate.id);
      await fetchUpdates();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelEditAccountUpdate = () => {
    hideAccountUpdateForm();
    if (updates.length === 0) {
      onClose();
    }
  };

  const handleCreateNewAccountUpdate = () => {
    setIsFormOpen(true);
    setEditingAccountUpdate({} as AccountUpdate);
  };

  const isFormOpenOrZeroAccountUpdates = isFormOpen || updates.length === 0;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
        Account "{account.title}" updates
        <IconButton aria-label="close" size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {isFormOpenOrZeroAccountUpdates ? (
        <DialogContent sx={{ py: 0, px: 1 }}>
          <AccountUpdateForm
            accountUpdate={editingAccountUpdate}
            onSubmit={handleAccountUpdateSubmit}
            onCancel={handleCancelEditAccountUpdate}
          />
        </DialogContent>
      ) : (
        <>
          <DialogContent sx={{ py: 0, px: 1 }}>
            <AccountUpdateList
              accountUpdates={updates}
              onEditAccountUpdate={handleEditAccountUpdate}
              onDeleteAccountUpdate={handleDeleteAccountUpdate}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCreateNewAccountUpdate}>
              Create new Update
            </Button>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default AccountUpdatesDialog;
