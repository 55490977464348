import { AccountModel } from "../../types";
import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { RawHtml } from "../RawHtml";
import { useTheme } from "@mui/material";

export const Requisites = ({ account }: { account: AccountModel }) => {
  const { palette } = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    if (gtag) {
      gtag("event", "copy_requisites", {
        event_category: "project",
        event_label: account.title,
        value: account.projectId,
      });
    }
    setOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function isUrl(account: AccountModel) {
    return account.requisites.startsWith("https://");
  }

  const getRequisitesUrl = (account: AccountModel) => {
    if (isUrl(account)) {
      const requisitesWithQuery = new URL(account.requisites);
      if (account.type === "monobank-jar") {
        requisitesWithQuery.searchParams.set("t", "ПередайДалі🤝🇺🇦");
      }
      return requisitesWithQuery.toString();
    }
    return "";
  };

  const handleRequisitesClick = (account: AccountModel) => {
    if (isUrl(account)) {
      if (gtag) {
        gtag("event", "open_link", {
          event_category: "project",
          event_label: account.title,
          value: account.projectId,
        });
      }
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  function formatUrl(account: AccountModel) {
    return (
      <a
        href={getRequisitesUrl(account)}
        target="_blank"
        style={{ color: palette.primary.light }}
      >
        {account.requisites}
      </a>
    );
  }

  const formatRequisites = (
    account: AccountModel
  ): string | React.ReactNode => {
    switch (account.type) {
      case "monobank-jar":
        return formatUrl(account);
      case "bankcard":
        return formatBankCard(account.requisites);
      case "bank":
        return <RawHtml>{account.requisites}</RawHtml>;
      default:
        if (isUrl(account)) {
          return formatUrl(account);
        }
        return account.requisites;
    }
  };

  const formatBankCard = (cardNumber: string): string => {
    const groups = cardNumber.match(/.{1,4}/g);
    return groups ? groups.join(" ") : "";
  };

  return (
    <>
      <CopyToClipboard text={account.requisites} onCopy={handleClick}>
        <ListItemIcon>
          <ContentCopyIcon />
        </ListItemIcon>
      </CopyToClipboard>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Скопійовано"
        action={action}
      />
      <ListItemText
        style={{ overflow: "scroll" }}
        primary={formatRequisites(account)}
        onClick={() => {
          handleRequisitesClick(account);
        }}
      />
    </>
  );
};
