import {
  Account,
  AccountUpdate,
  Project,
  ProjectUpdate,
  Repository,
  Tag,
  User,
} from "../types";
import { UserReview } from "../types/UserReview";
import api from "./server";
import { RequestQueryBuilder } from "@nestjsx/crud-request";

export class UsersRepository implements Repository<User> {
  async fetchAll(): Promise<User[]> {
    const response = await api.get<User[]>("/users");
    return response.data;
  }

  async fetchById(id: string | number): Promise<User | undefined> {
    const response = await api.get<User>(`/users/${id}`);
    return response.data;
  }

  async createOrUpdate(user: User): Promise<User> {
    if ("id" in user && user.id) {
      const response = await api.patch<User>(`/users/${user.id}`, user);
      return response.data;
    } else {
      const response = await api.post<User>(`/users`, user);
      return response.data;
    }
  }

  async deleteById(id: string | number): Promise<void> {
    await api.delete(`/users/${id}`);
  }
}

export class ProjectsRepository implements Repository<Project> {
  async fetchAll(): Promise<Project[]> {
    const response = await api.get<Project[]>("/projects?join=user&join=tags");
    return response.data;
  }

  async fetchById(id: string | number): Promise<Project | undefined> {
    const response = await api.get<Project>(`/projects/${id}`);
    return response.data;
  }

  async createOrUpdate(project: Project): Promise<Project> {
    if ("id" in project && project.id) {
      const response = await api.patch<Project>(
        `/projects/${project.id}`,
        project
      );
      return response.data;
    } else {
      const response = await api.post<Project>(`/projects`, project);
      return response.data;
    }
  }

  async deleteById(id: number | string): Promise<void> {
    await api.delete(`/projects/${id}`);
  }
}

export class TagsRepository implements Repository<Tag> {
  async fetchAll(): Promise<Tag[]> {
    const response = await api.get<Tag[]>("/tags");
    return response.data;
  }

  async fetchById(id: string | number): Promise<Tag | undefined> {
    const response = await api.get<Tag>(`/tags/${id}`);
    return response.data;
  }

  async createOrUpdate(tag: Tag): Promise<Tag> {
    if (tag.id) {
      const response = await api.patch<Tag>(`/tags/${tag.id}`, tag);
      return response.data;
    } else {
      const response = await api.post<Tag>(`/tags`, tag);
      return response.data;
    }
  }

  async deleteById(id: string | number): Promise<void> {
    await api.delete(`/tags/${id}`);
  }
}

export class AccountRepository implements Repository<Account> {
  private projectId: any;

  constructor(projectId: number | string) {
    this.projectId = projectId;
  }

  async createOrUpdate(item: Account): Promise<Account> {
    const data = {
      ...item,
      project: {
        id: this.projectId,
      },
    };
    if ("id" in item && item.id) {
      const response = await api.patch<Account>(`/accounts/${item.id}`, data);
      return response.data;
    } else {
      const response = await api.post<Account>(`/accounts`, data);
      return response.data;
    }
  }

  async deleteById(id: string | number): Promise<void> {
    await api.delete(`/accounts/${id}`);
  }

  async fetchAll(): Promise<Account[]> {
    const query = RequestQueryBuilder.create({
      filter: [{ field: "project.id", operator: "eq", value: this.projectId }],
    });
    const { data } = await api.get(`/accounts?${query.query()}`);
    return data;
  }

  async fetchById(id: string | number): Promise<Account | undefined> {
    return Promise.resolve(undefined);
  }
}

export class AccountUpdateRepository implements Repository<AccountUpdate> {
  private accountId: any;

  constructor(accountId: number | string) {
    this.accountId = accountId;
  }

  async createOrUpdate(item: AccountUpdate): Promise<AccountUpdate> {
    const data = {
      ...item,
      account: {
        id: this.accountId,
      },
    };
    if ("id" in item && item.id) {
      const response = await api.patch<AccountUpdate>(
        `/account-updates/${item.id}`,
        data
      );
      return response.data;
    } else {
      const response = await api.post<AccountUpdate>(`/account-updates`, data);
      return response.data;
    }
  }

  async deleteById(id: string | number): Promise<void> {
    await api.delete(`/account-updates/${id}`);
  }

  async fetchAll(): Promise<AccountUpdate[]> {
    const query = RequestQueryBuilder.create({
      filter: [{ field: "account.id", operator: "eq", value: this.accountId }],
    });
    const { data } = await api.get(`/account-updates?${query.query()}`);
    return data;
  }

  async fetchById(id: string | number): Promise<AccountUpdate | undefined> {
    return Promise.resolve(undefined);
  }
}

export class ProjectUpdateRepository implements Repository<ProjectUpdate> {
  private projectId: any;

  constructor(projectId: number | string) {
    this.projectId = projectId;
  }

  async createOrUpdate(item: ProjectUpdate): Promise<ProjectUpdate> {
    const formData = new FormData();
    formData.append("projectId", this.projectId);
    formData.append("text", item.text);
    formData.append("created_at", item.created_at);

    for (const file of item.files) {
      let blob = await fetch(file.result.toString()).then((res) => res.blob());
      formData.append(`files`, blob, file.filename);
    }

    if ("id" in item) {
      const response = await api.patch<ProjectUpdate>(
        `/project-updates/${item.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } else {
      const response = await api.post<ProjectUpdate>(
        `/project-updates`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    }
  }

  async deleteById(id: string | number): Promise<void> {
    await api.delete(`/project-updates/${id}`);
  }

  async fetchAll(): Promise<ProjectUpdate[]> {
    const query = RequestQueryBuilder.create({
      filter: [{ field: "project.id", operator: "eq", value: this.projectId }],
    });
    const { data } = await api.get(`/project-updates?${query.query()}`);
    return data;
  }

  async fetchById(id: string | number): Promise<ProjectUpdate | undefined> {
    const { data } = await api.get(`/project-updates/${id}`);
    return data;
  }
}

export class ReviewRepository implements Repository<UserReview> {
  async fetchAll(): Promise<UserReview[]> {
    const response = await api.get<UserReview[]>(
      "/reviews?join=author&join=target"
    );
    return response.data;
  }

  async fetchById(id: string | number): Promise<UserReview | undefined> {
    const response = await api.get<UserReview>(`/reviews/${id}`);
    return response.data;
  }

  async createOrUpdate(review: UserReview): Promise<UserReview> {
    if ("id" in review && review.id) {
      const response = await api.patch<UserReview>(
        `/reviews/${review.id}`,
        review
      );
      return response.data;
    } else {
      const response = await api.post<UserReview>(`/reviews`, review);
      return response.data;
    }
  }

  async deleteById(id: number | string): Promise<void> {
    await api.delete(`/reviews/${id}`);
  }
}
