import React, { useRef, useState } from "react";
import { UserModel } from "../../types";
import Avatar from "react-avatar-edit";
import { Alert, Box, Button, Grid, Typography, useTheme } from "@mui/material";
import UserHeader from "../user/UserHeader";
import UserChip from "../user/UserChip";
import { updateAvatar } from "../../api/protected";
import { UploadFile } from "../../types/File";
import { useNavigate } from "react-router-dom";

type ChangeAvatarProps = {
  user: UserModel;
};

const ChangeAvatar: React.FC<ChangeAvatarProps> = ({ user }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [file, setFile] = useState<UploadFile | null>(null);
  const [error, setError] = useState<string | null>(null);
  const avatarRef = useRef<HTMLElement | null>(null);

  const onClose = () => {
    setFile(null);
    if (avatarRef.current) {
      avatarRef.current.style.width = `320px`;
    }
  };

  const onCrop = (preview: string) => {
    if (file) {
      setFile({
        ...file,
        blob: preview,
      });
    }
  };

  const onBeforeFileLoad = (elem: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    if (!elem.target.files) {
      return;
    }
    if (elem.target.files[0].size > 1024 * 1024 * 10) {
      setError("Файл завеликого розміру");
      elem.target.value = "";
      return;
    }

    const wrapper = elem.target.parentElement;
    if (!wrapper) {
      return;
    }
    avatarRef.current = wrapper;
    const url = URL.createObjectURL(elem.target.files[0]);
    const img = new Image();

    img.onload = function () {
      const newWidth = Math.max(
        320,
        Math.round((img.width / img.height) * 320)
      );
      wrapper.style.width = `${newWidth}px`;
      URL.revokeObjectURL(img.src);
    };
    img.src = url;
  };

  const onFileLoad = async (
    data: React.ChangeEvent<HTMLInputElement> | File
  ) => {
    if ("name" in data) {
      setFile({
        name: data.name,
        blob: "",
      });
    }
  };
  const onSave = async () => {
    if (file) {
      try {
        const result = await updateAvatar(file);
        navigate("/profile");
        console.log("Image uploaded successfully:", result);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item>
          <Typography variant="h4" sx={{ marginTop: 1, marginBottom: 1 }}>
            Зміна фото
          </Typography>
          <Avatar
            labelStyle={{
              ...theme.typography,
              lineHeight: 320,
              width: "100%",
              display: "inline-block",
              fontSize: "1.5em",
              cursor: "pointer",
            }}
            width={320}
            height={320}
            onCrop={onCrop}
            onClose={onClose}
            onBeforeFileLoad={onBeforeFileLoad}
            onFileLoad={onFileLoad}
            exportAsSquare={true}
            label={"Оберіть файл"}
            cropRadius={100}
            exportSize={320}
          />
          {error && (
            <Alert variant="outlined" severity="error" sx={{ mt: 1 }}>
              {error}
            </Alert>
          )}
        </Grid>
        <Grid sx={{ ml: 2 }}></Grid>
        <Grid item>
          {file && (
            <Box>
              <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 1 }}>
                Приклади вашого фото
              </Typography>
              <Box>
                <UserHeader
                  user={{
                    ...user,
                    avatar_path: file.blob,
                  }}
                ></UserHeader>
                <Box sx={{ my: 2 }}>
                  <UserChip
                    user={{
                      ...user,
                      avatar_path: file.blob,
                    }}
                  ></UserChip>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box>
        <Button
          sx={{ mt: 1 }}
          onClick={onSave}
          disabled={!file}
          variant="contained"
        >
          Зберегти
        </Button>
      </Box>
    </Box>
  );
};

export default ChangeAvatar;
