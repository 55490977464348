import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { getProfile } from "../api";
import { UserModel } from "../types";

//TODO: rework hook to use context provider instead of making request directly in hook
export const useUser = () => {
  const { token } = useAuth();
  const [user, setUser] = useState<UserModel | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getProfile(token!);

        setUser(response);
      } catch (error) {
        console.error(error);
      }
    };

    if (token && !user) {
      fetchUser();
    }
  }, [user, token]);

  return user;
};
