import Login from "../components/Login";

const SignInPage = () => {
  return (
    <div>
      <Login></Login>
    </div>
  );
};

export default SignInPage;
