import React from "react";
import ChangeAvatar from "../../components/profile/ChangeAvatar";
import { useUser } from "../../hooks/useUser";

const ProfileEditAvatarPage = () => {
  const user = useUser();
  return <div>{user && <ChangeAvatar user={user}></ChangeAvatar>}</div>;
};

export default ProfileEditAvatarPage;
