import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useState } from "react";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { UserModel } from "../../types";
import { createUserReview } from "../../api/protected";
import { AxiosError } from "axios";
import Stack from "@mui/material/Stack";

const AddReview = ({
                     user,
                     onSubmit
                   }: {
  user: UserModel;
  onSubmit: () => Promise<void>;
}) => {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [text, setText] = useState("");
  const [formErrors, setFormErrors] = useState<
    Array<{ field: string; error: string }>
  >([]);
  const [isAdded, setIsAdded] = useState(false);
  const [isPositive, setIsPositive] = useState(true);

  const onClickAdd = () => {
    setFormErrors([]);
    setIsDialogOpen(true);
  };

  const onClickAddPositive = () => {
    setIsPositive(true);
    onClickAdd();
  };

  const onClickAddNegative = () => {
    setIsPositive(false);
    onClickAdd();
  };

  const getFieldErrors = (fieldName: string): string[] => {
    return formErrors
      .filter((error) => error.field === fieldName)
      .map((error) => error.error);
  };

  const onCloseDialog = () => setIsDialogOpen(false);

  const onSubmitForm = async () => {
    setFormErrors([]);

    if (!text) {
      setFormErrors([
        {
          field: "text",
          error: "Будь ласка, введіть текст пояснення"
        }
      ]);
      return;
    }

    try {
      await createUserReview({
        userId: user.id,
        text,
        isPositive
      });
      await onSubmit();
      setIsAdded(true);
      onCloseDialog();
    } catch (error: any | AxiosError) {
      if (error.response?.data?.errors) {
        setFormErrors(error.response?.data?.errors);
      } else {
        console.log(error);
      }
    }
  };

  return (
    <>
      {isAdded && (
        <Alert variant="filled" severity="success" sx={{ mt: 1 }}>
          Відгук додано
        </Alert>
      )}

      <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
        <Button
          variant="contained"
          startIcon={<HowToRegIcon />}
          onClick={onClickAddPositive}
          color="success"
        >
          Я довіряю волонтеру
        </Button>
        <Button
          variant="contained"
          startIcon={<PersonRemoveIcon />}
          onClick={onClickAddNegative}
          color="error"
        >
          Я не довіряю волонтеру
        </Button>
      </Stack>

      <Dialog
        open={isDialogOpen}
        onClose={onCloseDialog}
        fullWidth
        maxWidth="xl"
        fullScreen={lessThanSmall}
      >
        <DialogTitle
          sx={{ p: 1 }}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h5" component="span">
            {isPositive ? "Я довіряю волонтеру" : "Я не довіряю волонтеру"}
          </Typography>
          <IconButton aria-label="close" size="small" onClick={onCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider></Divider>
        <DialogContent sx={{ p: 1 }} style={{ flex: "none" }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {isPositive
              ? `Опишіть, будь ласка, чому ви довіряєте волонтеру ${user.first_name} ${user.last_name}`
              : `Опишіть, будь ласка, чому ви не довіряєте волонтеру ${user.first_name} ${user.last_name}`}
          </Typography>
          <TextField
            required
            fullWidth
            minRows={4}
            multiline
            value={text}
            onChange={(event) => {
              setText(event.target.value);
            }}
            error={Boolean(getFieldErrors("text").length)}
            helperText={getFieldErrors("text").join(", ")}
          ></TextField>
        </DialogContent>
        <DialogActions sx={{ p: 1, pt: 0 }}>
          <Button onClick={onCloseDialog}>Скасувати</Button>
          <Button variant="contained" onClick={onSubmitForm}>
            Надіслати верифікацію
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddReview;
