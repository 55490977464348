import React, { useState } from "react";
import useSWR from "swr";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TagForm, TagFormData } from "./TagForm";
import { Tag } from "../../types";
import { TagsRepository } from "../../api/repositories";
import { AxiosError } from "axios";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const tagsRepository = new TagsRepository();

const fetcher = (url: string) => tagsRepository.fetchAll();
const Tags = () => {
  const [editingTag, setEditingTag] = useState<Tag | null>();
  const [deletingTag, setDeletingTag] = useState<Tag | null>();
  const { data: tags, mutate, error } = useSWR("/tags", fetcher);
  const [formErrors, setFormErrors] = useState({});

  const handleAdd = () => {
    setFormErrors([]);
    setEditingTag({} as Tag);
  };
  const handleEdit = (tag: Tag) => {
    setFormErrors([]);
    setEditingTag(tag);
  };

  const handleDelete = (tag: Tag) => {
    setDeletingTag(tag);
  };

  const handleTagSubmit = async (tag: TagFormData) => {
    try {
      const tagData = { ...tag, id: editingTag ? editingTag.id : 0 };
      await tagsRepository.createOrUpdate(tagData);
      setEditingTag(null);
      setFormErrors([]);
      await mutate();
    } catch (error: any | AxiosError) {
      if (error.response?.data?.errors) {
        setFormErrors(error.response?.data?.errors);
      } else {
        console.log(error);
      }
    }
  };

  const handleTagDelete = async () => {
    if (deletingTag && deletingTag.id) {
      await tagsRepository.deleteById(deletingTag.id);
      setDeletingTag(null);
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "_",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const tag = params.row as Tag;
        return (
          <>
            <IconButton aria-label="edit" onClick={() => handleEdit(tag)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => handleDelete(tag)}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  const handleCloseDelete = () => {
    setDeletingTag(null);
  };
  const handleConfirmDelete = async () => {
    await handleTagDelete();
    console.log("Tag deleted:", deletingTag);
    setDeletingTag(null);
    await mutate();
  };

  let grid = <CircularProgress />;

  if (tags) {
    grid = <DataGrid rows={tags} columns={columns} pageSize={10} autoHeight />;
  }

  if (error) {
    grid = <div>Error fetching data</div>;
  }

  return (
    <>
      {grid}
      <Button
        variant="contained"
        onClick={handleAdd}
        style={{ marginTop: "1rem" }}
      >
        Add new
      </Button>
      <Dialog open={Boolean(editingTag)} onClose={() => setEditingTag(null)}>
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6">
            {" "}
            {editingTag?.id ? "Edit Tag" : "Add Tag"}
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setEditingTag(null)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ paddingTop: "0rem" }}>
          <TagForm
            tag={editingTag}
            onSubmit={handleTagSubmit}
            formErrors={formErrors}
          />
        </DialogContent>
        <DialogActions />
      </Dialog>

      <Dialog open={Boolean(deletingTag)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {deletingTag?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TagsCRUD: React.FC = () => {
  return (
    <Card>
      <CardContent>
        <Tags />
      </CardContent>
    </Card>
  );
};

export default TagsCRUD;
