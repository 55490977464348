import { useTheme } from "@mui/material";

interface RawHtmlProps {
  children: string;
  className?: string;
}

const processHtml = (html: string, color: string): string => {
  const urlRegex = /((https?:\/\/)[^\s,;]+)/g;
  return html
    .replace(
      urlRegex,
      `<a href="\$1" target="_blank" style="color: ${color}; text-decoration: underline;">\$1</a>`
    )
    .replace(/\n/g, "<br />");
};

// Process raw text: replace new line to <br> and link to <a>
export const RawHtml = ({ children, className = "" }: RawHtmlProps) => {
  const { palette } = useTheme();

  const html = processHtml(children, palette.primary.light);
  return (
    <div
      className={className}
      style={{ wordBreak: "break-word" }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
