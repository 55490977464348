import React, { createContext, ReactNode, useCallback, useEffect } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

interface AuthContextValue {
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextValue>({
  token: null,
  login: () => {},
  logout: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const {
    value: token,
    setItem: setToken,
    removeItem: removeToken,
  } = useLocalStorage("token");

  const navigate = useNavigate();
  const handleLogin = useCallback(
    (token: string) => {
      setToken(token);
      navigate("/");
    },
    [setToken, navigate]
  );

  useEffect(() => {
    // Check if the URL contains an access_token parameter
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("access_token");
    if (accessToken) {
      handleLogin(accessToken);
    }
  }, [handleLogin]);

  const handleLogout = () => {
    removeToken();
    navigate("/sign-in");
  };

  const authContextValue: AuthContextValue = {
    token,
    login: handleLogin,
    logout: handleLogout,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
