import React from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { ProjectsGrid } from "../components/projects/ProjectsGrid";
import { useProjects } from "../hooks/useProjects";
import { useTag } from "../hooks/useTag";
import { ProjectStatus } from "../types/ProjectStatus";

const TagPage = () => {
  const { id } = useParams<{ id: string }>();
  const tag = useTag({ tagId: id });
  const projects = useProjects({ tagId: id });
  const completedProjects = useProjects({
    tagId: id,
    status: ProjectStatus.Completed,
  });

  if (!tag) {
    return <Typography variant="h4">Завантаження...</Typography>;
  }

  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1 }}>
        Збори розділу "{tag.name}"
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 1, marginTop: 1 }}>
        {tag.description}
      </Typography>
      {projects ? (
        <>
          <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1 }}>
            Відкриті збори
          </Typography>
          <ProjectsGrid projects={projects}></ProjectsGrid>
        </>
      ) : (
        <></>
      )}
      {completedProjects ? (
        <>
          <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 2 }}>
            Завершені збори
          </Typography>
          <ProjectsGrid
            projects={completedProjects}
            status={ProjectStatus.Completed}
          ></ProjectsGrid>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TagPage;
