import { ProjectModel } from "../../types";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ApexOptions } from "apexcharts";
import { getProjectUpdates } from "../../api/public";
import {
  formatDate,
  formatDatetime,
  formatMoney,
  getProjectMoneyProgress,
  getProjectProgressPercent,
} from "../../helpers";
import ReactApexChart from "react-apexcharts";
import { BorderLinearProgress } from "../BorderLinearProgress";
import { ProjectStatus } from "../../types/ProjectStatus";

const DonationChart = ({ project }: { project: ProjectModel }) => {
  const { palette } = useTheme();
  const [background, setBackground] = useState(palette.background.paper);
  const [speed, setSpeed] = useState<number>(0);
  const [daysLeft, setDaysLeft] = useState<number>(0);
  const [chartLabels, setChartLabels] = useState<string[]>([]);

  const percent = getProjectProgressPercent(project);
  const percentValue = Math.min(percent, 100);
  const moneyProgress = getProjectMoneyProgress(project);
  const leftToRaise = project.goal - project.amount;

  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      const background = window.getComputedStyle(cardRef.current).background;
      setBackground(background);
    }
  }, [palette.mode]);

  const [chartData, setChartData] = useState<
    {
      name: string;
      type: string;
      fill: string;
      data: number[];
    }[]
  >([
    {
      name: "",
      type: "line",
      fill: "solid",
      data: [],
    },
  ]);

  const chartOptions: ApexOptions = {
    theme: {
      mode: palette.mode,
      monochrome: {
        shadeTo: palette.mode,
      },
    },
    chart: {
      background: background,
    },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    yaxis: {},
  };

  function groupDataByAccountId(data: any[]) {
    return data.reduce((acc, curr) => {
      const accountId = curr.accountId;
      if (!acc[accountId]) {
        acc[accountId] = [];
      }
      acc[accountId].push(curr);
      return acc;
    }, {});
  }

  function getAccountColor(accountId: string) {
    const colors = [
      "#008FFB",
      "#FF4560",
      "#FFC266",
      "#00E396",
      "#FEB019",
      "#FF66CC",
      "#3F51B5",
      "#63E2FF",
      "#FF7F7F",
      "#83C7FF",
    ];
    return colors[parseInt(accountId) % colors.length];
  }

  function fillGaps(arr: (number | null)[]): number[] {
    let prev: number | null = null;
    return arr.map((item) => {
      if (item !== null) {
        prev = item;
        return item;
      } else if (prev !== null) {
        return prev;
      } else {
        return 0;
      }
    });
  }

  useEffect(() => {
    const getAccountName = (accountId: string) => {
      if (!project) {
        return "";
      }
      const account = project.accounts.find(
        (a) => a.id === parseInt(accountId)
      );
      if (!account) {
        return "";
      }
      return account.title;
    };
    const fetchData = async () => {
      try {
        if (!project || !project.id) {
          return;
        }
        const response = await getProjectUpdates(project.id);
        const groupedData = groupDataByAccountId(response);
        const speeds: number[] = [];
        const data = Object.keys(groupedData).map((accountId) => {
          const accountData = groupedData[accountId];
          speeds.push(
            (accountData[accountData.length - 1].value - accountData[0].value) /
              accountData.length
          );
          const accountName = getAccountName(accountId);
          const accountColor = getAccountColor(accountId);
          const accountValues = accountData.map(
            (update: { value?: number }) => {
              if (!update.value) {
                return 0;
              }
              return parseFloat((update.value / 100).toFixed(2));
            }
          );
          return {
            name: accountName,
            type: "line",
            fill: "solid",
            data: fillGaps(accountValues),
            color: accountColor,
          };
        });
        const dates = response.map((update) =>
          formatDate(new Date(update.date))
        );
        setChartLabels(dates);
        setChartData(data);
        const speed = speeds.reduce((a, s) => a + s, 0);
        setSpeed(speed);
        const daysLeft = Math.ceil((project.goal - project.amount) / speed);
        setDaysLeft(daysLeft);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [project]);

  if (!project) {
    return <CircularProgress />;
  }

  const details = [`Середня швидкість: ${formatMoney(speed)} грн / день`];
  if (
    daysLeft < 250 &&
    daysLeft > 0 &&
    project.status !== ProjectStatus.Completed
  ) {
    details.push(`Очікувана к-сть днів до завершення збору: ${daysLeft}`);
    details.push(
      `(Розраховано з середньої швидкості за період обліку, якщо збір почався раніше - інформація може бути некоректною)`
    );
  }
  details.push(
    `Інформація оновлена: ${formatDatetime(new Date(project.updated_at))}`
  );

  return (
    <Card ref={cardRef}>
      <CardContent sx={{ p: 1, pt: 2 }}>
        <BorderLinearProgress
          sx={{ marginBottom: 1 }}
          variant="determinate"
          value={percentValue}
          title={moneyProgress}
        ></BorderLinearProgress>
        <Typography variant="h4">
          {moneyProgress} <span style={{ fontSize: 20 }}>грн</span>
        </Typography>

        {leftToRaise > 0 && (
          <Typography variant="h6">
            Залишилося зібрати: {formatMoney(leftToRaise)}{" "}
            <span style={{ fontSize: 14 }}>грн</span>
          </Typography>
        )}

        <ReactApexChart
          type="line"
          series={chartData}
          options={chartOptions}
          height={364}
        />
      </CardContent>
      <CardContent sx={{ py: 0 }}>
        {details.map((d) => (
          <Typography key={d} sx={{ fontSize: "0.85rem" }}>
            {d}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
};

export default DonationChart;
