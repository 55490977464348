import { Box, Card } from "@mui/material";
import React from "react";
import { useProjects } from "../../hooks/useProjects";
import { UserModel } from "../../types";
import { ProjectsGrid } from "../projects/ProjectsGrid";
import { TextWithIcon } from "../TextWithIcon";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DoneIcon from "@mui/icons-material/Done";
import { ProjectStatus } from "../../types/ProjectStatus";

const ProfileProjects = ({ user }: { user: UserModel }) => {
  const openProjects = useProjects({ userId: user.id });
  const completeProjects = useProjects({
    userId: user.id,
    status: ProjectStatus.Completed,
  });
  return (
    <>
      {openProjects.length ? (
        <Box sx={{ mb: 1 }}>
          <Card sx={{ mb: 1 }}>
            <Box p={1}>
              <TextWithIcon
                icon={TrendingUpIcon}
                text={`Відкриті збори (${openProjects.length})`}
              ></TextWithIcon>
            </Box>
          </Card>
          <ProjectsGrid isList projects={openProjects}></ProjectsGrid>
        </Box>
      ) : (
        <></>
      )}

      {completeProjects.length ? (
        <Box>
          <Card sx={{ mb: 1 }}>
            <Box p={1}>
              <TextWithIcon
                icon={DoneIcon}
                text={`Завершені збори (${completeProjects.length})`}
              ></TextWithIcon>
            </Box>
          </Card>

          <ProjectsGrid
            isList
            status={ProjectStatus.Completed}
            projects={completeProjects}
          ></ProjectsGrid>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProfileProjects;
