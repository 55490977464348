import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { ProjectModel } from "../types";
import { getProject } from "../api/public";
import { RawHtml } from "../components/RawHtml";
import { formatMoney, getProjectProgressPercent } from "../helpers";
import { AccountDetails } from "../components/projects/AccountDetails";
import { ProjectTags } from "../components/ProjectTags";
import { TextWithIcon } from "../components/TextWithIcon";
import ListIcon from "@mui/icons-material/List";
import DonationChart from "../components/projects/DonationChart";
import { useUser } from "../hooks/useUser";
import ProjectUpdatesSection from "../components/projects/ProjectUpdatesSection";

const ProjectPage = () => {
  const { id } = useParams();
  const user = useUser();
  const [project, setProject] = useState<ProjectModel | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        return;
      }
      const data = await getProject(parseInt(id));
      setProject(data);
    };
    fetchData();
  }, [id]);

  if (project === null) {
    return <></>;
  }

  const percent = getProjectProgressPercent(project);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <Typography variant="h4" sx={{ marginTop: 1, marginBottom: 1 }}>
            {project.title}
          </Typography>
          <Card>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={1}>
                <ProjectTags project={project}></ProjectTags>
              </Grid>
              <div style={{ padding: "10px 0" }}>
                <RawHtml>{project.description}</RawHtml>
              </div>
              {project.top_donation ? (
                <Typography>
                  Найбільший донат:{" "}
                  <strong>{formatMoney(project.top_donation)}</strong> грн
                </Typography>
              ) : (
                <></>
              )}
            </CardContent>
            <Divider></Divider>
            <CardContent sx={{ p: 1 }}>
              <TextWithIcon icon={ListIcon} text="Рахунки"></TextWithIcon>
            </CardContent>
            <List disablePadding sx={{ width: "100%" }}>
              {project.accounts.map((a) => (
                <AccountDetails key={a.id} account={a}></AccountDetails>
              ))}
            </List>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            variant="h4"
            sx={{ marginTop: 1, marginBottom: 1 }}
          >{`${percent.toFixed(2)}%`}</Typography>

          {project && <DonationChart project={project} />}
        </Grid>

        <ProjectUpdatesSection project={project} user={user} />
      </Grid>
    </>
  );
};

export default ProjectPage;
