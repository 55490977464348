import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { editProjectUpdate } from "../../api/protected";
import { AxiosError } from "axios";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import PostEditIcon from "@mui/icons-material/Edit";
import { ProjectUpdateModel } from "../../types";

export const EditProjectUpdateForm = ({
  data,
  onEdit,
}: {
  data: ProjectUpdateModel;
  onEdit: Function;
}) => {
  const [text, setText] = useState(data.text);

  const theme = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formErrors, setFormErrors] = useState<
    Array<{ field: string; error: string }>
  >([]);

  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const onClose = () => setIsDialogOpen(false);

  const getFieldErrors = (fieldName: string): string[] => {
    return formErrors
      .filter((error) => error.field === fieldName)
      .map((error) => error.error);
  };

  const onSubmit = async () => {
    setFormErrors([]);
    if (!text) {
      setFormErrors([
        {
          field: "text",
          error: "Будь ласка, введіть текст пояснення",
        },
      ]);
      return;
    }

    try {
      await editProjectUpdate({
        ...data,
        text,
      });
      onEdit();
      onClose();
    } catch (error: any | AxiosError) {
      if (error.response?.data?.errors) {
        setFormErrors(error.response?.data?.errors);
      } else {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<PostEditIcon />}
        onClick={() => {
          setFormErrors([]);
          setIsDialogOpen(true);
        }}
        color="success"
      >
        Редагувати
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        fullWidth
        maxWidth="xl"
        fullScreen={lessThanSmall}
      >
        <DialogTitle
          sx={{ p: 1 }}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h5">Редагування оновлення</Typography>
          <IconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider></Divider>
        <DialogContent sx={{ p: 1 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Опишіть, будь ласка, оновлення стосовно збору
          </Typography>
          <TextField
            required
            fullWidth
            minRows={4}
            multiline
            value={text}
            onChange={(event) => {
              setText(event.target.value);
            }}
            error={Boolean(getFieldErrors("text").length)}
            helperText={getFieldErrors("text").join(", ")}
          ></TextField>
        </DialogContent>
        <DialogActions sx={{ p: 1, pt: 0 }}>
          <Button onClick={onClose}>Скасувати</Button>
          <Button variant="contained" onClick={onSubmit}>
            Редагувати оновлення
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
