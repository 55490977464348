import {
  Avatar,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Zoom,
} from "@mui/material";
import React from "react";
import { imageUrl } from "../../helpers";

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    padding: 4,
    paddingBottom: 0,
  },
});

const UserAvatar = ({
  user,
}: {
  user: {
    first_name: string;
    last_name: string;
    avatar_path: string;
  };
}) => {
  const avatarUrl = imageUrl(user.avatar_path);

  return (
    <NoMaxWidthTooltip
      TransitionComponent={Zoom}
      title={
        avatarUrl && <img style={{ borderRadius: 3 }} src={avatarUrl} alt="" />
      }
    >
      <Avatar
        sx={{ bgcolor: "white", width: 56, height: 56, mr: 1 }}
        alt={`${user?.first_name} ${user?.last_name}`}
        src={imageUrl(user.avatar_path)}
      >
        {user?.first_name[0]}
        {user?.last_name[0]}
      </Avatar>
    </NoMaxWidthTooltip>
  );
};

export default UserAvatar;
