import { Alert, AlertTitle, Link } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useUser } from "../../hooks/useUser";

const FirstVisit = () => {
  const user = useUser();
  const { value: visited, setItem: setVisited } = useLocalStorage("visited");
  const [showBlock, setShowBlock] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!visited && !user) {
      setVisited("true");
      setShowBlock(true);
    }
  }, [setVisited, visited]);
  return (
    <>
      {showBlock && location.pathname !== "/about" && (
        <Alert variant="outlined" severity="info" icon={false} sx={{ mt: 1 }}>
          <AlertTitle>👋 Вперше в нас?</AlertTitle>
          <strong>Передай Далі 🇺🇦 ❤️</strong> — це благодійна платформа для підтримки українських військових.
          <br />
          Ваш внесок допомагає забезпечити важливі проєкти та технології, що захищають Україну.
          <br />
          Платформа забезпечує прозорість зборів коштів, дозволяє переглядати діяльність волонтерів та публікувати оновлення про стан зборів.
          <br />
          Дізнатися більше можна у розділі{" "}
          <Link to={"/about"} component={RouterLink}>
            "Про&nbsp;проект"
          </Link>
        </Alert>
      )}
    </>
  );
};

export default FirstVisit;
