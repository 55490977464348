import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Project, Tag, UserModel } from "../../types";
import MoneyTextField from "../MoneyTextField";
import { ProjectStatus } from "../../types/ProjectStatus";

interface ProjectFormProps {
  project: Project | null | undefined;
  users: UserModel[];
  tags: Tag[];
  onSubmit: (data: Project) => void;
  formErrors: Array<{ field: keyof Project; error: string }>;
}

export interface ProjectFormData {
  title: string;
  status: ProjectStatus;
  description: string;
  goal: number;
  amount: number;
  top_donation?: number;
  userId: number | string;
  tags: Tag[];
}

export const ProjectForm = ({
  project,
  users,
  tags,
  onSubmit,
  formErrors,
}: ProjectFormProps) => {
  const [formData, setFormData] = useState<Project>(
    project && "id" in project
      ? {
          id: project.id,
          title: project.title,
          status: project.status,
          description: project.description,
          goal: project.goal,
          amount: project.amount,
          top_donation: project.top_donation,
          user: project.user,
          tags: project.tags,
        }
      : {
          title: "",
          description: "",
          status: ProjectStatus.Draft,
          goal: 0,
          amount: 0,
          tags: [],
          user: null,
        }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit(formData);
  };
  const getFieldErrors = (fieldName: string): string[] => {
    return formErrors
      .filter((error) => error.field === fieldName)
      .map((error) => error.error);
  };

  const setUserId = (userId: number) => {
    setFormData((prevFormData) => ({ ...prevFormData, user: { id: userId } }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Title"
        name="title"
        value={formData.title}
        onChange={handleChange}
        error={Boolean(getFieldErrors("title").length)}
        helperText={getFieldErrors("title").join(", ")}
        required
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Status</InputLabel>
        <Select
          fullWidth
          required
          label="Status"
          value={formData.status}
          onChange={(e) => {
            setFormData((prevFormData) => ({
              ...prevFormData,
              status: e.target.value as ProjectStatus,
            }));
          }}
        >
          <MenuItem value={"draft"}>Draft</MenuItem>
          <MenuItem value={"on_review"}>On Review</MenuItem>
          <MenuItem value={"published"}>Published</MenuItem>
          <MenuItem value={"rejected"}>Rejected</MenuItem>
          <MenuItem value={"completed"}>Completed</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>User</InputLabel>
        <Select
          label="User"
          name="user"
          value={formData.user && "id" in formData.user && formData.user.id}
          fullWidth
          onChange={(e) => {
            setUserId(e.target.value as number);
          }}
        >
          <MenuItem key={0} value={0}>
            Не вибрано
          </MenuItem>
          {users.map((u) => (
            <MenuItem key={u.id} value={u.id}>
              {`#${u.id} ${u.first_name} ${u.last_name}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Description"
        name="description"
        multiline
        value={formData.description}
        onChange={handleChange}
        error={Boolean(getFieldErrors("description").length)}
        helperText={getFieldErrors("description").join(", ")}
        required
        fullWidth
        margin="normal"
      />
      <MoneyTextField
        label="Goal"
        name="goal"
        value={formData.goal}
        type="number"
        onChange={(value) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            goal: value || 0,
          }));
        }}
        error={Boolean(getFieldErrors("goal").length)}
        helperText={getFieldErrors("goal").join(", ")}
        required
        fullWidth
        margin="normal"
      />
      <MoneyTextField
        label="Top Donation"
        name="top_donation"
        value={formData.top_donation || 0}
        type="number"
        onChange={(value) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            top_donation: value || 0,
          }));
        }}
        error={Boolean(getFieldErrors("top_donation").length)}
        helperText={getFieldErrors("top_donation").join(", ")}
        required
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Tags</InputLabel>
        <Select
          label="Tags"
          name="tags"
          multiple
          value={formData.tags.map((tag) => tag.id)}
          onChange={(e) => {
            const selectedTags = e.target.value as number[];
            const newTags = tags.filter((tag) => selectedTags.includes(tag.id));
            setFormData((prevFormData) => ({ ...prevFormData, tags: newTags }));
          }}
          fullWidth
        >
          {tags.map((tag) => (
            <MenuItem key={tag.id} value={tag.id}>
              {tag.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};
