import React from "react";
import { ProjectModel } from "../types";
import { getProjectMoneyProgress, getProjectProgressPercent } from "../helpers";
import { Card, CardActions, Link, Stack, Typography } from "@mui/material";
import { BorderLinearProgress } from "./BorderLinearProgress";

import "./ProjectCard.css";
import { ProjectTags } from "./ProjectTags";

export const ProjectCard = ({
  project,
  hideIsCompleteTag = false,
}: {
  project: ProjectModel;
  hideIsCompleteTag?: boolean;
}) => {
  const percent = getProjectProgressPercent(project);
  const percentValue = Math.min(percent, 100);
  const percentText = `${percent.toFixed(2)}%`;

  return (
    <Card key={project.id} className="ProductCard">
      <Stack
        direction="column"
        sx={{ p: 1, pb: 2, height: "100%" }}
        alignContent={"flex-start"}
      >
        <Link href={`/projects/${project.id}`} sx={{ mb: "auto" }}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            {project.title}
          </Typography>
        </Link>
        <Typography variant="h5" sx={{ mb: 1 }}>
          {percentText}
        </Typography>
        <BorderLinearProgress
          sx={{ mb: 2 }}
          variant="determinate"
          value={percentValue}
        ></BorderLinearProgress>
        <Typography sx={{ mb: 1 }}>
          {getProjectMoneyProgress(project)}
        </Typography>
        <CardActions
          style={{
            display: "flex",
            height: "fit-content",
            overflowY: "scroll",
            padding: 0,
          }}
        >
          <ProjectTags project={project} hideIsComplete={hideIsCompleteTag} />
        </CardActions>
      </Stack>
    </Card>
  );
};
