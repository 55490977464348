import React from "react";
import { ProjectUpdateModel } from "../../types";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDatetime } from "../../helpers";
import { Asset } from "./Asset";

interface Props {
  projectUpdates: ProjectUpdateModel[];
  onEditProjectUpdate: (projectUpdate: ProjectUpdateModel) => void;
  onDeleteProjectUpdate: (projectUpdate: ProjectUpdateModel) => void;
}

export const ProjectUpdateList: React.FC<Props> = ({
  projectUpdates,
  onEditProjectUpdate,
  onDeleteProjectUpdate,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell width={250}>Date</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>Assets</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectUpdates.map((projectUpdate) => (
            <TableRow key={projectUpdate.id}>
              <TableCell>{projectUpdate.id}</TableCell>
              <TableCell>
                {formatDatetime(new Date(projectUpdate.created_at))}
              </TableCell>
              <TableCell>{projectUpdate.text}</TableCell>
              <TableCell>
                {projectUpdate.assets.map((asset) => (
                  <Asset asset={asset}></Asset>
                ))}
              </TableCell>
              <TableCell>
                <IconButton
                  aria-label="edit projectUpdate"
                  onClick={() => onEditProjectUpdate(projectUpdate)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete projectUpdate"
                  onClick={() => onDeleteProjectUpdate(projectUpdate)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
