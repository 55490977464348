import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Tag } from "../../types";

interface TagFormProps {
  tag?: Tag | null;
  onSubmit: (data: TagFormData) => void;
  formErrors: { [key: string]: string[] };
}

export interface TagFormData {
  name: string;
  description: string;
}

export const TagForm = ({ tag, onSubmit, formErrors }: TagFormProps) => {
  const [formData, setFormData] = useState<TagFormData>(
    tag
      ? {
          name: tag.name,
          description: tag.description,
        }
      : {
          name: "",
          description: "",
        }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        error={Boolean(formErrors.name)}
        helperText={formErrors.name && formErrors.name[0]}
        required
        fullWidth
        margin="normal"
      />

      <TextField
        label="Description"
        name="description"
        value={formData.description}
        onChange={handleChange}
        error={Boolean(formErrors.description)}
        helperText={formErrors.description && formErrors.description[0]}
        required
        multiline
        minRows={3}
        fullWidth
        margin="normal"
      />

      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};
