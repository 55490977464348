type MemoizedFunction<T> = (...args: any[]) => Promise<T>;

interface CacheEntry<T> {
  promise: Promise<T>;
  expirationTime: number;
}

export function memoizeWithTTL<T>(
  func: (...args: any[]) => Promise<T>,
  ttlMilliseconds: number
): MemoizedFunction<T> {
  const cache = new Map<string, CacheEntry<T>>();

  return (...args: any[]): Promise<T> => {
    const key = JSON.stringify(args);

    if (cache.has(key)) {
      const entry = cache.get(key)!;
      if (entry.expirationTime > Date.now()) {
        return entry.promise;
      } else {
        cache.delete(key);
      }
    }

    const promise = func(...args);
    const entry: CacheEntry<T> = {
      promise,
      expirationTime: Date.now() + ttlMilliseconds,
    };
    cache.set(key, entry);

    // Ensure that the promise resolves before returning it from the cache
    promise.then(() => {
      // You can perform additional actions here if needed
      // For example, you might want to update the cache if the promise rejects
      // or remove the entry if it's no longer needed
    });

    return promise;
  };
}
