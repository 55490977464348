import { AuthProvider } from "./contexts/AuthContext";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import NotFoundPage from "./pages/not_found";
import "./App.css";
import Header from "./components/Header";
import RequireAuth from "./components/RequireAuth";
import ProfilePage from "./pages/profile";
import { ThemeProvider } from "./contexts/ThemeContext";
import ProjectsPage from "./pages/projects";
import AboutPage from "./pages/about";
import SignUpPage from "./pages/sign_up";
import SignInPage from "./pages/sign_in";
import AdminPage from "./pages/admin";
import ProjectPage from "./pages/project";
import { Container } from "@mui/material";
import { Footer } from "./components/Footer";
import UserPage from "./pages/user";
import TagPage from "./pages/tag";
import SignOutPage from "./pages/sign_out";
import StatsPage from "./pages/stats";
import ResourcesPage from "./pages/resources";
import FirstVisit from "./components/alerts/FirstVisit";
import React from "react";
import UpdateProfileAlert from "./components/alerts/UpdateProfileAlert";
import { ProjectStatus } from "./types/ProjectStatus";

const App = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Header></Header>
        <Container maxWidth="xl" sx={{ px: { xs: 1, sm: 2 } }}>
          <FirstVisit></FirstVisit>
          <UpdateProfileAlert />
          <div className="App">
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="/sign-in" element={<SignInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/sign-out" element={<SignOutPage />} />
              <Route path="/projects" element={<ProjectsPage />} />
              <Route path="/projects/:id" element={<ProjectPage />} />
              <Route
                path="/completed-projects"
                element={<ProjectsPage status={ProjectStatus.Completed} />}
              />
              <Route
                path="/on-review-projects"
                element={<ProjectsPage status={ProjectStatus.OnReview} />}
              />
              <Route path="/users/:id" element={<UserPage />} />
              <Route path="/tags/:id" element={<TagPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/resources" element={<ResourcesPage />} />
              <Route path="/stats" element={<StatsPage />} />

              <Route
                path="/profile/*"
                element={
                  <RequireAuth>
                    <ProfilePage />
                  </RequireAuth>
                }
              >
              </Route>

              <Route
                path="/admin"
                element={
                  <RequireAuth>
                    <AdminPage />
                  </RequireAuth>
                }
              />

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
          <Footer></Footer>
        </Container>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
