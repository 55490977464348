import React from "react";

import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export function GalleryCarousel(props: {
  items: ReactImageGalleryItem[];
  startIndex: number;
}) {
  return (
    <ImageGallery
      items={props.items}
      showIndex={true}
      startIndex={props.startIndex}
      showThumbnails={false}
      showPlayButton={false}
      showFullscreenButton={false}
    />
  );
}
