import { Link as RouterLink, Route, Routes } from "react-router-dom";
import { Box, Button, ButtonGroup } from "@mui/material";
import Profile from "../components/Profile";
import ProfileEditPage from "./profile/profile_edit";
import ProfileEditAvatarPage from "./profile/edit_avatar";
import { useUser } from "../hooks/useUser";
import { ReactElement } from "react";
import ProjectCreateRequest from "../components/profile/ProjectCreateRequest";
import ProjectCreate from "../components/profile/ProjectCreate";

const ProfilePage = () => {
  const user = useUser();

  if (!user) {
    return <></>;
  }

  const links = [["/profile", "Профіль"]];
  const routes: { [key: string]: ReactElement } = {
    "": <Profile user={user} />,
    "edit": <ProfileEditPage />,
    "edit-avatar": <ProfileEditAvatarPage />,
  };
  if (user.roles.includes("user")) {
    links.push(["/profile/create-project-request", "Зареєструвати збір"]);
    routes["create-project-request"] = <ProjectCreateRequest />;
  }
  if (user.roles.includes("admin")) {
    links.push(["/profile/create-project", "Зареєструвати збір"]);
    routes["create-project"] = <ProjectCreate user={user} />;
  }

  return (
    <Box sx={{ mt: 1 }}>
      <ButtonGroup>
        {links.map((l) => (
          <Button component={RouterLink} key={l[0]} to={l[0]}>
            {l[1]}
          </Button>
        ))}
      </ButtonGroup>
      <Routes>
        {Object.entries(routes).map(([path, el]) => (
          <Route key={path} path={path} element={el} />
        ))}
      </Routes>
    </Box>
  );
};
export default ProfilePage;
