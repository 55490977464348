import React, { useState } from "react";
import { ProjectModel } from "../../types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { createProjectUpdate } from "../../api/protected";
import { AxiosError } from "axios";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import PostAddIcon from "@mui/icons-material/PostAdd";

export const AddProjectUpdateForm = ({
  project,
  onUpdateCreated,
}: {
  project: ProjectModel;
  onUpdateCreated: () => void;
}) => {
  const [text, setText] = useState("");

  const theme = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formErrors, setFormErrors] = useState<
    Array<{ field: string; error: string }>
  >([]);

  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const onClose = () => setIsDialogOpen(false);

  const getFieldErrors = (fieldName: string): string[] => {
    return formErrors
      .filter((error) => error.field === fieldName)
      .map((error) => error.error);
  };

  const onSubmit = async () => {
    setFormErrors([]);
    if (!project) return;
    if (!text) {
      setFormErrors([
        {
          field: "text",
          error: "Опишіть, будь ласка, оновлення стосовно збору",
        },
      ]);
      return;
    }

    try {
      await createProjectUpdate(String(project.id), { text });
      setText("");
      onUpdateCreated();
      onClose();
    } catch (error: any | AxiosError) {
      if (error.response?.data?.errors) {
        setFormErrors(error.response?.data?.errors);
      } else {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Grid item md={12} xs={12}>
        <Button
          sx={{ p: 1, mb: 1 }}
          variant="contained"
          startIcon={<PostAddIcon />}
          onClick={() => {
            setFormErrors([]);
            setIsDialogOpen(true);
          }}
          color="success"
        >
          Додати Оновлення
        </Button>
        <Dialog
          open={isDialogOpen}
          onClose={onClose}
          fullWidth
          maxWidth="xl"
          fullScreen={lessThanSmall}
        >
          <DialogTitle
            sx={{ p: 1 }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h5">Додати оновлення</Typography>
            <IconButton aria-label="close" size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider></Divider>
          <DialogContent sx={{ p: 1 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Опишіть, будь ласка, оновлення стосовно збору
            </Typography>
            <TextField
              required
              fullWidth
              minRows={4}
              multiline
              value={text}
              onChange={(event) => {
                setText(event.target.value);
              }}
              error={Boolean(getFieldErrors("text").length)}
              helperText={getFieldErrors("text").join(", ")}
            ></TextField>
          </DialogContent>
          <DialogActions sx={{ p: 1, pt: 0 }}>
            <Button onClick={onClose}>Скасувати</Button>
            <Button variant="contained" onClick={onSubmit}>
              Надіслати оновлення
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
