import { useState } from "react";

export const useLocalStorage = (key: string, initValue = null) => {
  const [value, setValue] = useState<string | null>(localStorage.getItem(key));

  const setItem = (value: string) => {
    localStorage.setItem(key, value);
    setValue(value);
  };

  const getItem = () => {
    return localStorage.getItem(key) || initValue;
  };

  const removeItem = () => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return { value, setItem, getItem, removeItem };
};
