import axios from "axios";

const DEFAULT_API_URL = process.env.REACT_APP_API_SERVER;

const api = axios.create({
  baseURL: DEFAULT_API_URL,
});

// add a request interceptor to attach the JWT to each request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
api.interceptors.response.use(
  (response) => {
    const authToken = response.headers["authtoken"];

    if (authToken) {
      localStorage.setItem("token", authToken);
    }

    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      !error.config.url.startsWith("/auth/login")
    ) {
      localStorage.removeItem("token");
      window.location.pathname = "/";
    }
    return Promise.reject(error);
  }
);

export default api;
