import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ my: 10 }}>
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          Not found :(
        </Typography>
        <Button variant="outlined" onClick={() => navigate("/")}>
          Come Back Home
        </Button>
      </Box>
    </>
  );
};

export default NotFoundPage;
