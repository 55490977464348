import { Project as ProjectType } from "./types";
import { ASSETS_HOST } from "./api";

export const numberWithSpaces = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const getProjectMoneyProgress = (project: ProjectType) => {
  return `${formatMoney(project.amount)} / ${formatMoney(project.goal)}`;
};

export const formatMoney = (value: number): string => {
  return numberWithSpaces((value / 100).toFixed(0));
};

export const getProjectProgressPercent = (project: ProjectType): number => {
  return (project.amount * 100) / project.goal;
};

export const formatDate = (date: Date) => {
  return date.toLocaleDateString("uk-UA", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
};

export const formatDatetime = (date: Date) => {
  return date
    .toLocaleTimeString("uk-UA", {
      hour: "numeric",
      minute: "numeric",
    })
    .concat(" ")
    .concat(formatDate(date));
};

export const convertGMTToLocal = (gmtTime: string | Date): string => {
  const dateObj = new Date(gmtTime);
  const localTime = new Date(
    dateObj.getTime() - dateObj.getTimezoneOffset() * 60000
  );
  return localTime.toISOString();
};

export const imageUrl = (path: string | undefined) => {
  if (path) {
    if (path.startsWith("data:")) {
      return path;
    }
    return `${ASSETS_HOST}/${path}`;
  }
  return "";
};
