import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Card, Grid, Link, Typography } from "@mui/material";
import { ProjectsGrid } from "../components/projects/ProjectsGrid";
import { getUser } from "../api/public";
import { UserModel } from "../types";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DoneIcon from "@mui/icons-material/Done";
import { TextWithIcon } from "../components/TextWithIcon";
import { useProjects } from "../hooks/useProjects";
import { RawHtml } from "../components/RawHtml";
import TrustVerification from "../components/user/TrustVerification";
import UserHeader from "../components/user/UserHeader";
import { ProjectStatus } from "../types/ProjectStatus";

const UserPage = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<UserModel | null>(null);
  const openProjects = useProjects({ userId: id });
  const completeProjects = useProjects({
    userId: id,
    status: ProjectStatus.Completed,
  });

  useEffect(() => {
    if (!id) {
      return;
    }
    const fetchData = async () => {
      const userData = await getUser(id);
      setUser(userData);
    };
    fetchData();
  }, [id]);

  if (!user) {
    return <Typography variant="h4">Завантаження...</Typography>;
  }

  const { email, telegram, instagram, facebook, twitter, description } = user;

  const contacts: { title: string; value: string }[] = [];

  if (telegram) {
    contacts.push({
      title: `Telegram: @${telegram}`,
      value: `https://t.me/${telegram}`,
    });
  }
  if (instagram) {
    contacts.push({
      title: `Instagram: @${instagram}`,
      value: `https://www.instagram.com/${instagram}`,
    });
  }
  if (facebook) {
    contacts.push({
      title: `Facebook: @${facebook}`,
      value: `https://www.facebook.com/${facebook}`,
    });
  }
  if (twitter) {
    contacts.push({
      title: `Twitter: @${twitter}`,
      value: `https://www.twitter.com/${twitter}`,
    });
  }
  if (email) {
    contacts.push({
      title: email,
      value: `mailto:${email}`,
    });
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} sx={{ mt: 1 }}>
        <UserHeader user={user}></UserHeader>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <Box p={1}>
            {description ? <RawHtml>{description}</RawHtml> : <></>}
            <Box>
              {contacts.map((c) => (
                <Typography variant="subtitle1" key={c.title + c.value}>
                  <Link href={c.value} key={c.value} target="_blank">
                    {c.title}
                  </Link>
                </Typography>
              ))}
            </Box>
            {user.roles.includes("volunteer") && (
              <TrustVerification user={user}></TrustVerification>
            )}
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={8}>
        {openProjects.length ? (
          <Box sx={{ mb: 1 }}>
            <Card sx={{ mb: 1 }}>
              <Box p={1}>
                <TextWithIcon
                  icon={TrendingUpIcon}
                  text={`Відкриті збори (${openProjects.length})`}
                ></TextWithIcon>
              </Box>
            </Card>
            <ProjectsGrid isList projects={openProjects}></ProjectsGrid>
          </Box>
        ) : (
          <></>
        )}

        {completeProjects.length ? (
          <Box>
            <Card sx={{ mb: 1 }}>
              <Box p={1}>
                <TextWithIcon
                  icon={DoneIcon}
                  text={`Завершені збори (${completeProjects.length})`}
                ></TextWithIcon>
              </Box>
            </Card>

            <ProjectsGrid
              isList
              status={ProjectStatus.Completed}
              projects={completeProjects}
            ></ProjectsGrid>
          </Box>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default UserPage;
