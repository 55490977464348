import React, { ChangeEvent, FormEvent, useState } from "react";
import { AccountUpdate } from "../../types";
import { Button, TextField } from "@mui/material";
import { convertGMTToLocal } from "../../helpers";
import MoneyTextField from "../MoneyTextField";

interface Props {
  accountUpdate: AccountUpdate | null;
  onSubmit: (accountUpdate: AccountUpdate) => void;
  onCancel: () => void;
}

export const AccountUpdateForm = ({
  accountUpdate,
  onSubmit,
  onCancel,
}: Props) => {
  const [amount, setAmount] = useState(accountUpdate?.amount || 0);
  const [comment, setComment] = useState(accountUpdate?.comment || "");
  const [createdAt, setCreatedAt] = useState(
    new Date(
      accountUpdate?.created_at
        ? convertGMTToLocal(accountUpdate.created_at) // convert GMT to local
        : convertGMTToLocal(new Date())
    )
      .toISOString()
      .slice(0, -5)
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const updatedAccountUpdate: AccountUpdate = {
      ...accountUpdate,
      amount,
      comment,
      created_at: new Date(createdAt).toISOString(), // convert local time back to GMT
    };

    onSubmit(updatedAccountUpdate);
  };

  return (
    <form onSubmit={handleSubmit}>
      <MoneyTextField
        margin="normal"
        name="amount"
        fullWidth
        required
        label="Amount"
        type="number"
        value={amount}
        onChange={(value) => {
          setAmount(value || 0);
        }}
      />

      <TextField
        margin="normal"
        fullWidth
        multiline
        required
        label="Comment"
        value={comment}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setComment(event.target.value)
        }
      />

      <TextField
        margin="normal"
        fullWidth
        required
        label="Created At"
        type="datetime-local"
        value={createdAt}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setCreatedAt(event.target.value)
        }
      />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
        <Button onClick={onCancel} sx={{ ml: 1 }}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
