import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { UserReview } from "../../types/UserReview";

import { UserModel } from "../../types";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import useSWR from "swr";
import { ReviewRepository } from "../../api/repositories";
import { formatDatetime } from "../../helpers";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

const reviewsRepository = new ReviewRepository();

const fetcher = async (url: string) => {
  return (await reviewsRepository.fetchAll()) as UserReview[];
};

const Reviews = () => {
  const { data: reviews, mutate, error } = useSWR("/reviews", fetcher);
  const [deletingReview, setDeletingReview] = useState<UserReview | null>();

  const handleCloseDelete = () => {
    setDeletingReview(null);
  };

  function handleDelete(project: UserReview) {
    setDeletingReview(project);
  }

  const handleProjectDelete = async () => {
    if (deletingReview && "id" in deletingReview) {
      await reviewsRepository.deleteById(deletingReview.id);
      setDeletingReview(null);
      await mutate();
    }
  };

  const authorColumn = (author?: UserModel) => {
    if (!author) {
      return <>Не вибрано</>;
    }
    return <>{`${author.first_name} ${author.last_name}`}</>;
  };
  const targetColumn = (target?: UserModel) => {
    if (!target) {
      return <>Не вибрано</>;
    }
    return <>{`${target.first_name} ${target.last_name}`}</>;
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "author",
      headerName: "Author",
      width: 250,
      renderCell: (params) => <>{authorColumn(params.value)}</>,
    },
    {
      field: "target",
      headerName: "Target",
      width: 250,
      renderCell: (params) => <>{targetColumn(params.value)}</>,
    },
    {
      field: "text",
      headerName: "Text",
      width: 250,
    },
    {
      field: "isPositive",
      type: "boolean",
      headerName: "Is positive",
      renderCell: ({ value: isPositive }) => (
        <>{(isPositive as boolean) ? <HowToRegIcon /> : <PersonRemoveIcon />}</>
      ),
    },
    {
      field: "created_at",
      headerName: "Created at",
      type: "dateTime",
      width: 150,
      renderCell: (params) => {
        const review = params.row as UserReview;
        return <>{formatDatetime(new Date(review.created_at))}</>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="delete project"
            onClick={() => handleDelete(params.row as UserReview)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  if (error) {
    return <div>Failed to load reviews</div>;
  }

  if (!reviews) {
    return <CircularProgress />;
  }

  return (
    <>
      <DataGrid rows={reviews} columns={columns} pageSize={10} autoHeight />
      <Dialog open={Boolean(deletingReview)}>
        <DialogTitle>Delete review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {deletingReview?.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button onClick={handleProjectDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ReviewsCRUD: React.FC = () => {
  return (
    <Card>
      <CardContent>
        <Reviews />
      </CardContent>
    </Card>
  );
};

export default ReviewsCRUD;
