import React from "react";
import { Typography } from "@mui/material";
import { ProjectsGrid } from "../components/projects/ProjectsGrid";
import { useProjects } from "../hooks/useProjects";
import { ProjectStatus } from "../types/ProjectStatus";

const ProjectsPage = ({
  status = ProjectStatus.Published,
}: {
  status?: ProjectStatus;
}) => {
  const projects = useProjects({ status });
  const getTitle = () => {
    switch (status) {
      case ProjectStatus.OnReview:
        return "Збори на розгляді";
      case ProjectStatus.Published:
        return "Відкриті збори";
      case ProjectStatus.Completed:
        return "Завершені збори";
      case ProjectStatus.Rejected:
        return "Скасовані збори";
      default:
        return "Збори";
    }
  };
  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1 }}>
        {getTitle()}
      </Typography>
      <ProjectsGrid status={status} projects={projects}></ProjectsGrid>
    </>
  );
};

export default ProjectsPage;
