import { Box, Icon, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import React from "react";
import UserAvatar from "./UserAvatar";

const UserHeader = ({
  user,
}: {
  user: {
    first_name: string;
    last_name: string;
    avatar_path: string;
    is_verified: boolean;
  };
}) => {
  const name = `${user.first_name} ${user.last_name}`;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <UserAvatar user={user}></UserAvatar>
      <Typography variant="h4">
        {name}
        {user.is_verified ? (
          <Icon fontSize="large">
            <VerifiedIcon />
          </Icon>
        ) : (
          <></>
        )}
      </Typography>
    </Box>
  );
};

export default UserHeader;
