import { imageUrl } from "../../helpers";

import { Modal, Box, Typography } from "@mui/material";
import React, { useState } from "react";

export const Asset = ({ asset }: { asset: string }) => {
  const imageFormats = [".jpg", ".jpeg", ".png", ".gif"]; // Add the supported image formats here

  const isImage = imageFormats.some((format) =>
    asset.toLowerCase().endsWith(format)
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (isImage) {
    return (
      <>
        <img
          style={{ maxWidth: "100px", cursor: "pointer" }}
          alt={asset}
          src={imageUrl(asset)}
          onClick={handleOpenModal}
        />
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              border: "none",
              boxShadow: 24,
              p: 4,
              outline: 0,
              maxHeight: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                maxWidth: "80%",
                maxHeight: "80%",
                objectFit: "contain",
              }}
              alt={asset}
              src={imageUrl(asset)}
            />
          </Box>
        </Modal>
      </>
    );
  }

  return <p>{asset}</p>;
};
