import { LoginData, RegisterData, UserModel } from "../types";
import api from "./server";
import { memoizeWithTTL } from "../helpers/memoize";

export const ASSETS_HOST = process.env.REACT_APP_ASSETS_HOST;

interface AuthResponse {
  access_token: string;
}

export async function register(data: RegisterData): Promise<AuthResponse> {
  const response = await api.post(`/auth/register`, data);
  return response.data;
}

export async function login(data: LoginData): Promise<AuthResponse> {
  const response = await api.post(`/auth/login`, data);
  return response.data;
}

export const getProfile = memoizeWithTTL(
  async (token: string): Promise<UserModel> => {
    const response = await api.get(`/auth/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
  500
);
