import { useEffect, useState } from "react";
import { Tag } from "../types";
import { getTag } from "../api/public";

export const useTag = ({ tagId }: { tagId: string | undefined }) => {
  const [tag, setTag] = useState<Tag>();

  useEffect(() => {
    if (tagId) {
      getTag(tagId).then(setTag);
    }
  }, [tagId]);

  return tag;
};
