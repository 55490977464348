import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { Project, ProjectModel, User, UserModel } from "../../types";

import { ProjectForm } from "./ProjectForm";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ListIcon from "@mui/icons-material/List";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useSWR from "swr";
import {
  ProjectsRepository,
  TagsRepository,
  UsersRepository,
} from "../../api/repositories";
import { formatMoney } from "../../helpers";
import { ProjectAccountsDialog } from "./ProjectAccounts";
import { ProjectProjectUpdatesDialog } from "./ProjectUpdates";
import { ProjectStatus } from "../../types/ProjectStatus";

const PROJECT_STATUSES = Object.values(ProjectStatus).map((value) => ({
  label: value.toUpperCase().slice(0, 1) + value.replaceAll("_", " ").slice(1),
  value: value,
}));

const projectsRepository = new ProjectsRepository();

const fetcher = async (url: string) => {
  return (await projectsRepository.fetchAll()) as ProjectModel[];
};

const usersRepository = new UsersRepository();

const usersFetcher = async (url: string) => {
  return (await usersRepository.fetchAll()) as UserModel[];
};

const tagsRepository = new TagsRepository();
const tagsFetcher = (url: string) => tagsRepository.fetchAll();

const Projects = () => {
  const [editingProject, setEditingProject] = useState<Project | null>();
  const [deletingProject, setDeletingProject] = useState<ProjectModel | null>();
  const [accountsProject, setAccountsProject] = useState<ProjectModel | null>();
  const [updatesProject, setUpdatesProject] = useState<ProjectModel | null>();
  const { data: projects, mutate, error } = useSWR("/projects", fetcher);
  const { data: users } = useSWR("/users", usersFetcher);
  const { data: tags } = useSWR("/tags", tagsFetcher);
  const [formErrors, setFormErrors] = useState([]);

  const handleAdd = () => {
    setFormErrors([]);
    setEditingProject({} as Project);
  };
  const handleEdit = (project: Project) => {
    setFormErrors([]);
    setEditingProject(project);
  };
  const handleCloseDelete = () => {
    setDeletingProject(null);
  };
  const handleCloseAccounts = () => {
    setAccountsProject(null);
  };

  const handleDelete = (project: ProjectModel) => {
    setDeletingProject(project);
  };

  const handleAccounts = (project: ProjectModel) => {
    setAccountsProject(project);
  };

  const handleUpdates = (project: ProjectModel) => {
    setUpdatesProject(project);
  };

  const handleCloseUpdates = () => {
    setUpdatesProject(null);
  };

  const handleProjectSubmit = async (project: Project) => {
    try {
      const projectData: Project = {
        ...project,
      };
      await projectsRepository.createOrUpdate(projectData);

      setEditingProject(null);
      setFormErrors([]);
      await mutate();
    } catch (error: any | AxiosError) {
      if (error.response?.data?.errors) {
        setFormErrors(error.response?.data?.errors);
      } else {
        console.log(error);
      }
    }
  };

  const handleProjectDelete = async () => {
    if (deletingProject && "id" in deletingProject) {
      await projectsRepository.deleteById(deletingProject.id);
      setDeletingProject(null);
      await mutate();
    }
  };

  const userColumn = (user?: User) => {
    if (!user) {
      return <>Не вибрано</>;
    }
    return <>{`${user.first_name} ${user.last_name}`}</>;
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    { field: "title", headerName: "Title", width: 350 },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      renderCell: (params) => <>{formatMoney(params.value)}</>,
    },
    {
      field: "goal",
      headerName: "Goal",
      type: "number",
      renderCell: (params) => <>{formatMoney(params.value)}</>,
    },
    {
      field: "user",
      headerName: "User",
      width: 200,
      renderCell: (params) => <>{userColumn(params.value)}</>,
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      width: 200,
      valueOptions: PROJECT_STATUSES,
    },
    {
      field: "updates",
      headerName: "Updates",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          aria-label="project updates"
          onClick={() => handleUpdates(params.row as ProjectModel)}
        >
          <ListIcon />
        </IconButton>
      ),
    },
    {
      field: "accounts",
      headerName: "Accounts",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          aria-label="project accounts"
          onClick={() => handleAccounts(params.row as ProjectModel)}
        >
          <ListIcon />
        </IconButton>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      filterable: false,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="view project"
            onClick={() =>
              window.open(`${window.location.origin}/projects/${params.row.id}`)
            }
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            aria-label="edit project"
            onClick={() => handleEdit(params.row as ProjectModel)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete project"
            onClick={() => handleDelete(params.row as ProjectModel)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  if (error) {
    return <div>Failed to load projects</div>;
  }

  if (!projects) {
    return <CircularProgress />;
  }

  return (
    <>
      <DataGrid rows={projects} columns={columns} pageSize={10} autoHeight />
      <Button
        variant="contained"
        color="primary"
        onClick={handleAdd}
        style={{ marginTop: "1rem" }}
      >
        Add project
      </Button>
      <Dialog
        open={Boolean(editingProject)}
        onClose={() => setEditingProject(null)}
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {editingProject && "id" in editingProject
            ? `Edit project #${editingProject.id}`
            : "Create project"}
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setEditingProject(null)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingTop: "0rem" }}>
          <ProjectForm
            project={editingProject}
            users={users || []}
            tags={tags || []}
            onSubmit={handleProjectSubmit}
            formErrors={formErrors}
          />
        </DialogContent>
        <DialogActions />
      </Dialog>
      <Dialog open={Boolean(deletingProject)}>
        <DialogTitle>Delete Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {deletingProject?.title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button onClick={handleProjectDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {accountsProject && (
        <ProjectAccountsDialog
          project={accountsProject}
          open={Boolean(accountsProject)}
          onClose={handleCloseAccounts}
        />
      )}
      {updatesProject && (
        <ProjectProjectUpdatesDialog
          project={updatesProject}
          open={Boolean(updatesProject)}
          onClose={handleCloseUpdates}
        />
      )}
    </>
  );
};

const ProjectsCRUD: React.FC = () => {
  return (
    <Card>
      <CardContent>
        <Projects />
      </CardContent>
    </Card>
  );
};

export default ProjectsCRUD;
