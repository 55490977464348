import React, { useState } from "react";
import { ImageData } from "../../types/File";
import { Box, Button, FormHelperText, Modal, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { imageUrl } from "../../helpers";

const shortid = require("shortid");

export interface ImageFileUploadProps {
  files: ImageData[];
  onChange: (images: ImageData[]) => void;
  imageTypes?: string;
}

const DEFAULT_FILE_TYPES = "image/*";

export const ImageFileUpload = (props: ImageFileUploadProps) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [files, setFiles] = useState<ImageData[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFiles = e.target.files;
    if (!inputFiles) {
      return;
    }
    const images: ImageData[] = [...props.files];
    const newErrors: string[] = [];

    for (let i = 0; i < inputFiles.length; i++) {
      if (!inputFiles[i]) {
        continue;
      }

      if (inputFiles[i].size > 10 * 1024 * 1024) {
        newErrors.push(
          `Файл ${inputFiles[i].name} завеликого розміру (максимум 10 Мб) `
        );
        continue;
      }

      const filename = inputFiles[i].name.toString();
      const filetype = inputFiles[i].type.toString();

      let reader = new FileReader();

      let file = inputFiles[i];

      const imageLoading = new Promise<ImageData | null>((resolve) => {
        reader.onloadend = () => {
          if (!reader.result) {
            return resolve(null);
          }
          const image: ImageData = {
            id: shortid.generate().toString(),
            filename: filename,
            filetype: filetype,
            result: reader.result,
          };
          console.log("image: ", image);
          resolve(image);
        };
      });

      reader.readAsDataURL(file);

      const image = await imageLoading;

      if (image) {
        images.push(image);
      }
    }
    setErrors(newErrors);
    setFiles(images);
    props.onChange(images);
  };

  const deleteSelectFile = (id: string) => {
    const result = files.filter((ImageData: ImageData) => ImageData.id !== id);
    setFiles(result);
    props.onChange(result);
  };

  const handleOpenModal = (image: string) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <Box>
      <div className="kb-file-upload">
        <div className="file-upload-box">
          <input
            type="file"
            id="fileupload"
            className="file-upload-input"
            onChange={onChange}
            multiple
            accept={props.imageTypes ? props.imageTypes : DEFAULT_FILE_TYPES}
          />
          <span>
            Drag and drop or{" "}
            <span className="file-link">Choose your files</span>
          </span>
        </div>
      </div>
      <div>
        {errors.map((error: string) => {
          return <FormHelperText error={true}>{error}</FormHelperText>;
        })}
      </div>
      <div className="kb-attach-box">
        {files?.map((ImageData: ImageData) => {
          return (
            <div className="file-atc-box" key={ImageData.id}>
              {ImageData.filename.match(/.(jpg|jpeg|png|gif|svg|jfif)$/i) ? (
                <div
                  className="file-image"
                  onClick={() => handleOpenModal(ImageData.result.toString())}
                >
                  {" "}
                  <img src={imageUrl(ImageData.result.toString())} alt="" />
                </div>
              ) : (
                <div className="file-image">
                  <i className="far fa-file-alt"></i>
                </div>
              )}
              <div className="file-detail">
                <Typography variant="h6">{ImageData.filename}</Typography>
                <div className="file-actions">
                  <Button
                    variant="outlined"
                    onClick={() => deleteSelectFile(ImageData.id)}
                  >
                    <DeleteIcon></DeleteIcon>
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            boxShadow: 24,
            p: 4,
            outline: 0,
            maxHeight: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected Image"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};
