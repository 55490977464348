import React from "react";
import { ProjectModel } from "../../types";
import { Grid } from "@mui/material";
import { ProjectCard } from "../ProjectCard";
import { ProjectStatus } from "../../types/ProjectStatus";

export const ProjectsGrid = ({
  isList = false,
  projects,
  status = ProjectStatus.Published,
}: {
  isList?: boolean;
  projects: ProjectModel[];
  status?: ProjectStatus;
}) => {
  return (
    <Grid container spacing={1} direction="row">
      {[...projects].map((p) => (
        <Grid item md={isList ? 12 : 6} lg={isList ? 12 : 4} xs={12} key={p.id}>
          <ProjectCard
            project={p}
            key={p.id}
            hideIsCompleteTag={status === ProjectStatus.Completed}
          ></ProjectCard>
        </Grid>
      ))}
    </Grid>
  );
};
