import { Divider, Link, Typography } from "@mui/material";
import React from "react";
import config from "src/config";

const ResourcesPage = () => {
  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1 }}>
        Корисні ресурси
      </Typography>

      <Typography variant="h6" sx={{ marginBottom: 1, marginTop: 1 }}>
        Покупка дронів
      </Typography>
      <ul>
        <li>
          <Link
            target="_blank"
            href="https://docs.google.com/document/d/1rGWKP3XPL8xbE9HJZ6hPjIc42L0a3XaGvv24IcqyS4U/edit"
          >
            Інструкція #укрСпецІмпорт
          </Link>
        </li>
      </ul>

      <Divider sx={{ mt: 4 }}></Divider>
      <Typography variant="body2" sx={{ mt: 4 }}>
        Якщо у вас є пропозиції щодо додавання ресурсів на дану сторінку, будь
        ласка, зверніться до розробників за допомогою функції{" "}
        <Link href={config.feedbackAccountUrl} target="_blank" variant="body2">
          "Зворотний звʼязок"
        </Link>
      </Typography>
    </>
  );
};

export default ResourcesPage;
