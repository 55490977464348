import React from "react";
import { AccountUpdateModel } from "../../types";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDatetime, formatMoney } from "../../helpers";

interface Props {
  accountUpdates: AccountUpdateModel[];
  onEditAccountUpdate: (accountUpdate: AccountUpdateModel) => void;
  onDeleteAccountUpdate: (accountUpdate: AccountUpdateModel) => void;
}

export const AccountUpdateList: React.FC<Props> = ({
  accountUpdates,
  onEditAccountUpdate,
  onDeleteAccountUpdate,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell width={250}>Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Comment</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accountUpdates.map((accountUpdate) => (
            <TableRow key={accountUpdate.id}>
              <TableCell>{accountUpdate.id}</TableCell>
              <TableCell>
                {formatDatetime(new Date(accountUpdate.created_at))}
              </TableCell>
              <TableCell>{formatMoney(accountUpdate.amount)}</TableCell>
              <TableCell>{accountUpdate.comment}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="edit accountUpdate"
                  onClick={() => onEditAccountUpdate(accountUpdate)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete accountUpdate"
                  onClick={() => onDeleteAccountUpdate(accountUpdate)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
