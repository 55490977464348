import React, { useCallback, useEffect, useState } from "react";
import { Account, AccountModel, ProjectModel } from "../../types";
import { AccountForm } from "./AccountForm";
import { AccountList } from "./AccountList";
import { AccountRepository } from "../../api/repositories";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountUpdatesDialog from "./AccountUpdates";

const accountRepository = (projectId: number) =>
  new AccountRepository(projectId);

interface ProjectAccountsDialogProps {
  open: boolean;
  onClose: () => void;
  project: ProjectModel;
}

export const ProjectAccountsDialog = ({
  open,
  onClose,
  project,
}: ProjectAccountsDialogProps) => {
  const repository = accountRepository(project.id);

  const [editingAccount, setEditingAccount] = useState<Account | null>(null);
  const [updatesAccount, setUpdatesAccount] = useState<AccountModel | null>(
    null
  );
  const [accounts, setAccounts] = useState<AccountModel[]>([]);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const fetchAccounts = useCallback(async () => {
    const accountsData = await repository.fetchAll();
    setAccounts(accountsData as AccountModel[]);
  }, [project]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts, project]);

  const handleAccountSubmit = async (account: Account) => {
    try {
      await repository.createOrUpdate(account);
      setEditingAccount(null);
      setIsFormOpen(false);
      await fetchAccounts();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditAccount = (account: AccountModel) => {
    setEditingAccount(account);
    setIsFormOpen(true);
  };

  const handleDeleteAccount = async (account: AccountModel) => {
    try {
      if (account.id) {
        await repository.deleteById(account.id);
      }
      await fetchAccounts();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenUpdatesAccount = (account: AccountModel) => {
    setUpdatesAccount(account);
  };

  const handleCancelEditAccount = () => {
    setEditingAccount(null);
    setIsFormOpen(false);
    if (accounts.length === 0) {
      onClose();
    }
  };

  const handleOpenForm = () => {
    setIsFormOpen(true);
    setEditingAccount({} as Account);
  };

  const isFormOpenOrZeroAccounts = isFormOpen || accounts.length === 0;

  const isMainDialogOpen = open && updatesAccount === null;
  const isUpdateDialogOpen = updatesAccount !== null;
  return (
    <>
      <Dialog open={isMainDialogOpen} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          Project Accounts
          <IconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 0, px: 1 }}>
          <div style={{ display: isFormOpenOrZeroAccounts ? "none" : "block" }}>
            <AccountList
              accounts={accounts}
              onEditAccount={handleEditAccount}
              onDeleteAccount={handleDeleteAccount}
              onOpenUpdatesAccount={handleOpenUpdatesAccount}
            />
          </div>
          {isFormOpenOrZeroAccounts && (
            <AccountForm
              account={editingAccount}
              onSubmit={handleAccountSubmit}
              onCancel={handleCancelEditAccount}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ pt: isFormOpenOrZeroAccounts ? 0 : 1 }}>
          {!isFormOpenOrZeroAccounts && (
            <>
              <Button variant="contained" onClick={handleOpenForm}>
                Create new account
              </Button>
              <Button onClick={onClose}>Close</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {updatesAccount && (
        <AccountUpdatesDialog
          open={isUpdateDialogOpen}
          onClose={() => {
            setUpdatesAccount(null);
          }}
          account={updatesAccount}
        ></AccountUpdatesDialog>
      )}
    </>
  );
};
