import { ProjectModel, UserModel } from "../../types";
import { Card, Grid } from "@mui/material";
import { TextWithIcon } from "../TextWithIcon";
import FeedIcon from "@mui/icons-material/Feed";
import { AddProjectUpdateForm } from "./AddProjectUpdateForm";
import ProjectUpdates from "./ProjectUpdates";
import React, { useState } from "react";
import { getProject } from "../../api/public";

const ProjectUpdatesSection = ({
  project,
  user,
}: {
  project: ProjectModel;
  user: UserModel | null;
}) => {
  const [updates, setUpdates] = useState(project.updates);

  const onNewUpdateCreated = async () => {
    //@TODO: fetch update only (need to add backend endpoint)
    const data = await getProject(project.id);
    setUpdates(data.updates);
  };
  const isAuthor = Boolean(user) && project.user?.id === user?.id;
  return (
    <>
      {(updates.length !== 0 || isAuthor) && (
        <Grid item md={12} xs={12}>
          <Card sx={{ p: 1, mb: 1 }}>
            <TextWithIcon
              icon={FeedIcon}
              text="Оновлення"
              button={
                project.user?.id === user?.id ? (
                  <AddProjectUpdateForm
                    onUpdateCreated={onNewUpdateCreated}
                    project={project}
                  />
                ) : null
              }
            ></TextWithIcon>
          </Card>
          <ProjectUpdates
            updates={updates}
            isAuthor={isAuthor}
            onEdit={onNewUpdateCreated}
          ></ProjectUpdates>
        </Grid>
      )}
    </>
  );
};

export default ProjectUpdatesSection;
