import React, { createContext, ReactNode } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";
import { CssBaseline, PaletteMode, useMediaQuery } from "@mui/material";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import { ThemeOptions } from "@mui/material/styles/createTheme";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

interface ThemeContextValue {
  theme: PaletteMode;
  setTheme: (theme: PaletteMode) => void;
}

const DEFAULT_THEME = "dark";
export const ThemeContext = createContext<ThemeContextValue>({
  theme: DEFAULT_THEME,
  setTheme: () => {},
});

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const { value, getItem, setItem } = useLocalStorage("theme");

  const getOrDefault = (value: string | null): PaletteMode => {
    if (!value) {
      value = prefersDarkMode ? "dark" : "light";
    }
    return value as PaletteMode;
  };

  const handleToggleTheme = () => {
    let value = getOrDefault(getItem());
    setItem(value !== "dark" ? "dark" : "light");
  };

  const themeContextValue: ThemeContextValue = {
    theme: getOrDefault(value),
    setTheme: handleToggleTheme,
  };

  const customizations: ThemeOptions =
    themeContextValue.theme === "dark"
      ? {
          palette: {
            mode: themeContextValue.theme,
          },
          //dark theme
        }
      : {
          //light theme
          palette: {
            mode: themeContextValue.theme,
            background: {
              paper: "#eaeaea",
              default: "#e0e0e0",
            },
          },
        };

  const themeObj = createTheme({
    ...customizations,
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  });

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <MuiThemeProvider theme={themeObj}>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
