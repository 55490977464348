import React, { useCallback, useEffect, useState } from "react";
import { ProjectModel, ProjectUpdate, ProjectUpdateModel } from "../../types";
import { ProjectUpdateForm } from "./ProjectUpdateForm";
import { ProjectUpdateList } from "./ProjectUpdateList";
import { ProjectUpdateRepository } from "../../api/repositories";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const projectUpdateRepository = (projectId: number) =>
  new ProjectUpdateRepository(projectId);

interface ProjectProjectUpdatesDialogProps {
  open: boolean;
  onClose: () => void;
  project: ProjectModel;
}

export const ProjectProjectUpdatesDialog = ({
  open,
  onClose,
  project,
}: ProjectProjectUpdatesDialogProps) => {
  const repository = projectUpdateRepository(project.id);

  const [editingProjectUpdate, setEditingProjectUpdate] =
    useState<ProjectUpdate | null>(null);
  const [ProjectUpdates, setProjectUpdates] = useState<ProjectUpdateModel[]>(
    []
  );
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const fetchProjectUpdates = useCallback(async () => {
    const ProjectUpdatesData = await repository.fetchAll();
    setProjectUpdates(ProjectUpdatesData as ProjectUpdateModel[]);
  }, [project]);

  useEffect(() => {
    fetchProjectUpdates();
  }, [fetchProjectUpdates, project]);

  const handleProjectUpdateSubmit = async (ProjectUpdate: ProjectUpdate) => {
    try {
      await repository.createOrUpdate(ProjectUpdate);
      setEditingProjectUpdate(null);
      setIsFormOpen(false);
      await fetchProjectUpdates();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditProjectUpdate = (ProjectUpdate: ProjectUpdateModel) => {
    setEditingProjectUpdate(ProjectUpdate);
    setIsFormOpen(true);
  };

  const handleDeleteProjectUpdate = async (
    ProjectUpdate: ProjectUpdateModel
  ) => {
    try {
      if (ProjectUpdate.id) {
        await repository.deleteById(ProjectUpdate.id);
      }
      await fetchProjectUpdates();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelEditProjectUpdate = () => {
    setEditingProjectUpdate(null);
    setIsFormOpen(false);
    if (ProjectUpdates.length === 0) {
      onClose();
    }
  };

  const handleOpenForm = () => {
    setIsFormOpen(true);
    setEditingProjectUpdate({} as ProjectUpdate);
  };

  const isFormOpenOrZeroProjectUpdates =
    isFormOpen || ProjectUpdates.length === 0;

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          Project Updates
          <IconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 0, px: 1 }}>
          <div
            style={{
              display: isFormOpenOrZeroProjectUpdates ? "none" : "block",
            }}
          >
            <ProjectUpdateList
              projectUpdates={ProjectUpdates}
              onEditProjectUpdate={handleEditProjectUpdate}
              onDeleteProjectUpdate={handleDeleteProjectUpdate}
            />
          </div>
          {isFormOpenOrZeroProjectUpdates && (
            <ProjectUpdateForm
              projectUpdate={editingProjectUpdate}
              onSubmit={handleProjectUpdateSubmit}
              onCancel={handleCancelEditProjectUpdate}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ pt: isFormOpenOrZeroProjectUpdates ? 0 : 1 }}>
          {!isFormOpenOrZeroProjectUpdates && (
            <>
              <Button variant="contained" onClick={handleOpenForm}>
                Create new Update
              </Button>
              <Button onClick={onClose}>Close</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
