import { AccountModel } from "../../types";
import React from "react";
import { Collapse, List, ListItemButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ListItemText from "@mui/material/ListItemText";
import { formatMoney } from "../../helpers";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PaymentIcon from "@mui/icons-material/Payment";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Requisites } from "./Requisites";

export const AccountDetails = ({ account }: { account: AccountModel }) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    if (!account.requisites) {
      return;
    }
    setOpen(!open);
  };

  const getIcon = () => {
    if (account.type === "monobank-jar") {
      return <InventoryIcon />;
    }
    if (account.type === "bankcard") {
      return <PaymentIcon />;
    }
    if (account.type === "cash") {
      return <PaymentsIcon />;
    }
    if (account.type === "bank") {
      return <AccountBalanceIcon />;
    }
    return <AccountBalanceWalletIcon />;
  };
  return (
    <>
      <ListItemButton sx={{ pl: 2 }} onClick={handleClick}>
        <ListItemIcon>{getIcon()}</ListItemIcon>
        <ListItemText
          primary={account.title}
          secondary={
            account.amount ? formatMoney(account.amount) : <span>&nbsp;</span>
          }
        />
        {account.requisites && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton>
            <Requisites account={account}></Requisites>
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};
