import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Project as ProjectType } from "../types";
import { getProjects } from "../api/public";
import { BorderLinearProgress } from "../components/BorderLinearProgress";
import { formatMoney } from "../helpers";
import { ProjectStatus } from "../types/ProjectStatus";

const StatsPage = () => {
  const [openProjects, setOpenProjects] = useState<ProjectType[]>([]);
  const [completeProjects, setCompleteProjects] = useState<ProjectType[]>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalGoal, setTotalGoal] = useState(0);

  const percent = (totalAmount * 100) / totalGoal;
  const moneyProgress = `${formatMoney(totalAmount)} / ${formatMoney(
    totalGoal
  )}`;

  useEffect(() => {
    const fetchOpenProjects = async () => {
      const data = await getProjects({ status: ProjectStatus.Published });
      setOpenProjects(data);
      const { totalAmount, totalGoal } = data.reduce(
        (a, p) => {
          return {
            totalAmount: a.totalAmount + p.amount,
            totalGoal: a.totalGoal + p.goal,
          };
        },
        { totalAmount: 0, totalGoal: 0 }
      );
      setTotalAmount(totalAmount);
      setTotalGoal(totalGoal);
    };
    const fetchCompleteProjects = async () => {
      const data = await getProjects({ status: ProjectStatus.Completed });
      setCompleteProjects(data);
    };
    fetchOpenProjects();
    fetchCompleteProjects();
  }, []);

  if (openProjects.length === 0 && completeProjects.length === 0) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1 }}>
        Статистика
      </Typography>

      <Typography variant="h5" sx={{ marginBottom: 1 }}>
        Загальний прогрес по відкритим зборам: {`${percent.toFixed(2)}%`}
      </Typography>
      <BorderLinearProgress
        sx={{ marginBottom: 1 }}
        height={40}
        borderRadius={10}
        variant="determinate"
        value={percent}
        title={moneyProgress}
      ></BorderLinearProgress>

      <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1 }}>
        {moneyProgress}
      </Typography>
      <Typography variant="h5" sx={{ marginBottom: 1, marginTop: 1 }}>
        Відкриті збори: {openProjects.length}
      </Typography>
      <Typography variant="h5" sx={{ marginBottom: 1, marginTop: 1 }}>
        Завершені збори: {completeProjects.length}
      </Typography>
    </>
  );
};

export default StatsPage;
