import ProfileEdit, { FormData } from "../../components/profile/ProfileEdit";
import { useUser } from "../../hooks/useUser";
import React, { useState } from "react";
import { updateProfile } from "../../api/protected";
import { User } from "../../types";
import { Alert, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProfileEditPage = () => {
  const navigate = useNavigate();
  const user = useUser();

  const [formErrors, setFormErrors] = useState([]);

  const handleSubmit = async (data: FormData) => {
    try {
      await updateProfile(data as User);
      setFormErrors([]);
      navigate(0);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormErrors(error.response?.data?.errors);
      } else {
        console.log(error);
      }
    }
  };

  const getForm = () => {
    if (user) {
      return (
        <ProfileEdit
          user={user}
          onSubmit={handleSubmit}
          formErrors={formErrors}
        />
      );
    }
    return <></>;
  };

  return (
    <div>
      <Typography variant="h4" sx={{ marginTop: 1, marginBottom: 1 }}>
        Редагування профілю
      </Typography>
      <Grid container>
        <Grid item md={12}>
          {user && !user.roles.includes("user") && (
            <Alert variant="outlined" severity="info" sx={{ mt: 1 }}>
              Будь ласка, введіть ваші імʼя та прізвище (українською мовою), а
              також заповніть хоча б одну соціальну мережу.
              <br />
              Дані вимоги зменшують кількість недобросовісних користувачів.{" "}
              <br />
              Після внесення інформації ви зможете залишати відгуки про
              волонтерів та створювати заявки на власні збори.
            </Alert>
          )}
        </Grid>
        <Grid item md={6}>
          {getForm()}
        </Grid>
      </Grid>
    </div>
  );
};
export default ProfileEditPage;
