import { useEffect, useState } from "react";
import { ProjectModel } from "../types";
import { getProjects } from "../api/public";
import { ProjectStatus } from "../types/ProjectStatus";

export const useProjects = ({
  userId = "",
  tagId = "",
  status = ProjectStatus.Published,
}: {
  userId?: string | number;
  tagId?: string;
  status?: ProjectStatus;
}) => {
  const [projects, setProjects] = useState<ProjectModel[]>([]);

  useEffect(() => {
    getProjects({ userId, tagId, status }).then(setProjects);
  }, [tagId, userId, status]);

  return projects;
};
