import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ProjectFormData } from "./ProjectForm";
import { AccountType, UserModel } from "../../types";
import { createProject } from "../../api/protected";

interface ProjectCreateProps {
  user: UserModel;
}

const ProjectCreate: React.FC<ProjectCreateProps> = ({
  user,
}: ProjectCreateProps) => {
  const [formData, setFormData] = useState<ProjectFormData>({
    title: "",
    description: "",
    goal: 0,
    tagIds: [],
    userId: user ? user.id : null,
    accounts: [],
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  // const handleTagChange = (tagIds: string[]) => {
  //   setFormData({ ...formData, tagIds });
  // };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await createProject({
      title: formData.title,
      description: formData.description,
      accounts: [
        {
          title: "",
          amount: 0,
          type: "monobank-jar",
          requisites: "",
        },
      ],
    });
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} sx={{ marginTop: 1 }}>
          <Grid item md={6}>
            <Typography variant="h4">Деталі проекту</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Назва"
              type="text"
              fullWidth
              name="title"
              value={formData.title}
              onChange={handleChange}
              helperText="Заголовок збору, коротка назва, що буде відображатися на головній сторінці"
            />
            <TextField
              margin="dense"
              id="description"
              label="Опис"
              type="text"
              fullWidth
              multiline
              minRows={3}
              name="description"
              value={formData.description}
              onChange={handleChange}
              helperText="Детальний опис збору, можуть бути додані посилання"
            />
            <TextField
              margin="dense"
              id="goal"
              label="Ціль"
              type="number"
              fullWidth
              name="goal"
              value={formData.goal}
              onChange={handleChange}
              helperText="Сума кошті, які потрібно зібрати (в грн)"
            />
            {/*<TagsSelect value={formData.tagIds} onChange={handleTagChange} />*/}
          </Grid>
          <Grid item md={6}>
            <Typography variant="h4">Реквізити</Typography>
            <FormControl fullWidth margin="dense">
              <InputLabel>Тип рахунку</InputLabel>
              <Select
                fullWidth
                required
                label="Type"
                // value={type}
                // onChange={(e) => {
                //   const selectedType = e.target.value as AccountType;
                //   setType(selectedType);
                // }}
              >
                <MenuItem value={"monobank-jar"}>Монобанка</MenuItem>
                <MenuItem value={"bankcard"}>Банківська карта</MenuItem>
                <MenuItem value={"paypal"}>Pay Pal</MenuItem>
                <MenuItem value={"cash"}>Готівка</MenuItem>
                <MenuItem value={"bank"}>Банківські реквізити</MenuItem>
              </Select>
              <FormHelperText>Оберіть зі списку</FormHelperText>
            </FormControl>

            <TextField
              margin="dense"
              id="description"
              label="Реквізити"
              type="text"
              fullWidth
              multiline
              minRows={3}
              // value={formData.description}
              // onChange={handleChange}
              // helperText="Детальний опис збору, можуть бути додані посилання"
            />

            <FormControl fullWidth margin="dense">
              <Button variant="contained">Додати реквізити</Button>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 1 }}
            >
              Створити
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ProjectCreate;
