import { ProjectUpdateModel } from "../../types";

import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { ReactImageGalleryItem } from "react-image-gallery";
import { formatDatetime, imageUrl } from "../../helpers";

import { RawHtml } from "../RawHtml";
import React from "react";
import { EditProjectUpdateForm } from "./EditProjectUpdateForm";
import CloseIcon from "@mui/icons-material/Close";
import { GalleryCarousel } from "../GalleryCarousel";

const ProjectUpdates = ({
  updates,
  onEdit,
  isAuthor,
}: {
  updates: ProjectUpdateModel[];
  onEdit: Function;
  isAuthor: boolean;
}) => {
  // @TODO add sorting on backend
  const sortedUpdates = updates.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  const [galleryItems, setGalleryItems] = React.useState<ReactImageGalleryItem[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const getGalleryItems = (u: ProjectUpdateModel): ReactImageGalleryItem[] => {
    return u.assets.map((file, fileIndex) => {
      return {
        original: imageUrl(file),
        originalAlt: `${fileIndex}`,
      };
    });
  };

  const handleImageClick = (u: ProjectUpdateModel, imageIndex: number) => {
    setGalleryItems(getGalleryItems(u));
    setSelectedImageIndex(imageIndex);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(0);
    setIsModalOpen(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        {sortedUpdates.map((u, index) => (
          <Grid lg={12} xs={12} item key={index}>
            <Card sx={{ p: 1, pb: 2 }}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="h6">
                  {formatDatetime(new Date(u.created_at))}
                </Typography>
                {index === 0 && isAuthor && (
                  <Box sx={{ ml: "auto", mb: 1 }}>
                    <EditProjectUpdateForm data={u} onEdit={onEdit} />
                  </Box>
                )}
              </Box>
              <Divider sx={{ mb: 1 }} />
              <Box sx={{ pl: 0 }}>
                <RawHtml>{u.text}</RawHtml>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {u.assets.map((file, fileIndex) => (
                  <Box
                    key={fileIndex}
                    sx={{
                      cursor: "pointer",
                      maxWidth: "200px",
                      maxHeight: "300px",
                      width: "100%",
                      height: "100%",
                    }}
                    onClick={() => handleImageClick(u, fileIndex)}
                  >
                    <img
                      src={imageUrl(file)}
                      alt={`${fileIndex}`}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal
        id={`project-update-images-modal`}
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            outline: 0,
          }}
        >
          <IconButton
            size="large"
            aria-label="close"
            aria-controls="project-update-images-modal"
            color="inherit"
            sx={{
              position: "absolute",
              top: "0",
              right: "0",
            }}
            onClick={handleCloseModal}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <GalleryCarousel
            items={galleryItems}
            startIndex={selectedImageIndex}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ProjectUpdates;
