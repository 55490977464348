import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React from "react";
import UserAvatar from "./user/UserAvatar";
import { UserModel } from "../types";
import ProfileProjects from "./profile/ProfileProjects";

const Profile = ({ user }: { user: UserModel }) => {
  return (
    <Box sx={{ mt: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Box>
            <Card sx={{ p: 1, pt: 0 }}>
              <Box sx={{ my: 1, display: "flex", alignItems: "flex-start" }}>
                <UserAvatar user={user}></UserAvatar>

                {user.roles.includes("user") ? (
                  <Button
                    variant="outlined"
                    sx={{ ml: 1, mt: 1 }}
                    component={RouterLink}
                    to="/profile/edit-avatar"
                  >
                    Змінити фото
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
              <Box>
                <Typography variant="h4" sx={{ mt: 0, mb: 1 }}>
                  {user?.first_name} {user?.last_name}
                </Typography>
                <Typography variant="body1">Email: {user?.email}</Typography>
                <Typography variant="body1">
                  Telegram: {user?.telegram}
                </Typography>
                <Typography variant="body1">
                  Instagram: {user?.instagram}
                </Typography>
                <Typography variant="body1">
                  Facebook: {user?.facebook}
                </Typography>
                <Typography variant="body1">
                  Twitter: {user?.twitter}
                </Typography>
                <Typography variant="body1">
                  Про себе: <br />
                  {user?.description}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{ mt: 1 }}
                component={RouterLink}
                to="/profile/edit"
              >
                Редагувати дані
              </Button>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <ProfileProjects user={user}></ProfileProjects>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
