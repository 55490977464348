import React, { useState } from "react";
import useSWR from "swr";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { UserForm, UserFormData } from "./UserForm";
import { User, UserModel } from "../../types";
import { UsersRepository } from "../../api/repositories";
import { AxiosError } from "axios";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import { formatDatetime } from "../../helpers";
import VisibilityIcon from "@mui/icons-material/Visibility";

const usersRepository = new UsersRepository();

const fetcher = (url: string) => usersRepository.fetchAll();
const Users = () => {
  const [editingUser, setEditingUser] = useState<User | null>();
  const [deletingUser, setDeletingUser] = useState<UserModel | null>();
  const { data: users, mutate, error } = useSWR("/users", fetcher);
  const [formErrors, setFormErrors] = useState({});

  const handleAdd = () => {
    setFormErrors([]);
    setEditingUser({} as User);
  };
  const handleEdit = (user: UserModel) => {
    setFormErrors([]);
    setEditingUser(user);
  };
  const handleToggleIsActive = async (user: UserModel) => {
    await usersRepository.createOrUpdate({
      ...user,
      is_active: !user.is_active,
    });
    await mutate();
  };
  const handleToggleIsVerified = async (user: UserModel) => {
    await usersRepository.createOrUpdate({
      ...user,
      is_verified: !user.is_verified,
    });
    await mutate();
  };

  const handleDelete = (user: UserModel) => {
    setDeletingUser(user);
  };

  const handleUserSubmit = async (user: UserFormData) => {
    try {
      await usersRepository.createOrUpdate(user);
      setEditingUser(null);
      setFormErrors([]);
      await mutate();
    } catch (error: any | AxiosError) {
      if (error.response?.data?.errors) {
        setFormErrors(error.response?.data?.errors);
      } else {
        console.log(error);
      }
    }
  };

  const handleUserDelete = async () => {
    if (deletingUser && deletingUser.id) {
      await usersRepository.deleteById(deletingUser.id);
      setDeletingUser(null);
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "first_name", headerName: "First name", width: 150 },
    { field: "last_name", headerName: "Last name", width: 150 },
    {
      field: "is_active",
      type: "boolean",
      headerName: "Is Active",
      renderCell: (params) => {
        const user = params.row as UserModel;
        return (
          <IconButton
            aria-label="is_active"
            onClick={() => handleToggleIsActive(user)}
          >
            {user.is_active ? <CheckCircleIcon /> : <NotInterestedIcon />}
          </IconButton>
        );
      },
    },
    {
      field: "is_verified",
      type: "boolean",
      headerName: "Is Verified",
      renderCell: (params) => {
        const user = params.row as UserModel;
        return (
          <IconButton
            aria-label="is_verified"
            onClick={() => handleToggleIsVerified(user)}
          >
            {user.is_verified ? <VerifiedIcon /> : <NotInterestedIcon />}
          </IconButton>
        );
      },
    },
    { field: "telegram", headerName: "Telegram", width: 150 },
    { field: "roles", headerName: "Roles", width: 200 },
    {
      field: "updated_at",
      type: "dateTime",
      width: 150,
      renderCell: (params) => {
        const user = params.row as UserModel;
        return <>{formatDatetime(new Date(user.updated_at))}</>;
      },
    },
    {
      field: "created_at",
      type: "dateTime",
      width: 150,
      renderCell: (params) => {
        const user = params.row as UserModel;
        return <>{formatDatetime(new Date(user.created_at))}</>;
      },
    },
    {
      field: "_",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const user = params.row as UserModel;
        return (
          <>
            <IconButton
              aria-label="view"
              onClick={() =>
                window.open(`${window.location.origin}/users/${params.row.id}`)
              }
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton aria-label="edit" onClick={() => handleEdit(user)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => handleDelete(user)}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  const handleCloseDelete = () => {
    setDeletingUser(null);
  };
  const handleConfirmDelete = async () => {
    await handleUserDelete();
    console.log("User deleted:", deletingUser);
    setDeletingUser(null);
    await mutate();
  };

  let grid = <CircularProgress />;

  if (users) {
    grid = <DataGrid rows={users} columns={columns} pageSize={10} autoHeight />;
  }

  if (error) {
    grid = <div>Error fetching data</div>;
  }

  return (
    <>
      {grid}
      <Button
        variant="contained"
        onClick={handleAdd}
        style={{ marginTop: "1rem" }}
      >
        Add new
      </Button>
      <Dialog open={Boolean(editingUser)} onClose={() => setEditingUser(null)}>
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6">
            {" "}
            {editingUser && "id" in editingUser ? "Edit User" : "Add User"}
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setEditingUser(null)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ paddingTop: "0rem" }}>
          <UserForm
            user={editingUser}
            onSubmit={handleUserSubmit}
            formErrors={formErrors}
          />
        </DialogContent>
        <DialogActions />
      </Dialog>

      <Dialog open={Boolean(deletingUser)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {deletingUser?.first_name}{" "}
            {deletingUser?.last_name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UsersCRUD: React.FC = () => {
  return (
    <Card>
      <CardContent>
        <Users />
      </CardContent>
    </Card>
  );
};

export default UsersCRUD;
