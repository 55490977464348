import { Avatar, Chip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import { imageUrl } from "../../helpers";

const UserChip = ({
  user,
}: {
  user: {
    id: number | string;
    first_name: string;
    last_name: string;
    avatar_path: string;
    is_verified: boolean;
  };
}) => {
  const props: { avatar?: React.ReactElement; icon?: React.ReactElement } = {};
  if (user.avatar_path) {
    props.avatar = (
      <Avatar
        alt={`${user?.first_name} ${user?.last_name}`}
        src={imageUrl(user.avatar_path)}
      >
        {user?.first_name[0]}
        {user?.last_name[0]}
      </Avatar>
    );
  } else {
    props.icon = user.is_verified ? <VerifiedIcon /> : <AccountCircleIcon />;
  }
  return (
    <Chip
      clickable={Boolean(user && user.id)}
      component={RouterLink}
      to={`/users/${user.id}`}
      color="primary"
      label={`${user.first_name} ${user.last_name}`}
      {...props}
    />
  );
};

export default UserChip;
