import { ProjectModel } from "../types";
import { Chip, Grid } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UserChip from "./user/UserChip";
import { ProjectStatus } from "../types/ProjectStatus";

export const ProjectTags = ({
  project,
  hideIsComplete = false,
}: {
  project: ProjectModel;
  hideIsComplete?: boolean;
}) => {
  const user = project.user;
  const tags = project.tags;

  const tagEls = [];
  if (project.status === ProjectStatus.Completed && !hideIsComplete) {
    tagEls.push(
      <Chip icon={<CheckCircleIcon />} label="Завершений" color="success" />
    );
  }
  if (user) {
    tagEls.push(<UserChip user={user}></UserChip>);
  } else {
    tagEls.push(
      <Chip icon={<AccountCircleIcon />} label="Анонімний волонтер" />
    );
  }

  tagEls.push(
    ...tags.map((t) => (
      <Chip
        clickable
        component={RouterLink}
        to={`/tags/${t.id}`}
        key={t.id}
        label={t.name}
      ></Chip>
    ))
  );

  return (
    <>
      {tagEls.map((t, i) => (
        <Grid key={i} item>
          {t}
        </Grid>
      ))}
    </>
  );
};
