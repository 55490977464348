import React, { ChangeEvent } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

interface MoneyTextFieldProps
  extends Omit<TextFieldProps, "value" | "onChange"> {
  value: number | null;
  onChange: (value: number | null) => void;
}

const MoneyTextField: React.FC<MoneyTextFieldProps> = ({
  value,
  onChange,
  ...props
}) => {
  const formattedValue = value != null ? (value / 100).toFixed(2) : "";

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    onChange(inputValue ? parseInt(inputValue) : null);
  };

  return (
    <TextField
      value={formattedValue}
      type="text"
      onChange={handleChange}
      {...props}
    />
  );
};

export default MoneyTextField;
