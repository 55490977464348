import { UserModel } from "../../types";
import { useUser } from "../../hooks/useUser";
import React, { useState } from "react";
import {
  Alert,
  Collapse,
  Divider,
  Link,
  List,
  ListItemButton,
} from "@mui/material";
import useSWR from "swr";
import { getUserReviews } from "../../api/public";
import { Link as RouterLink } from "react-router-dom";
import AddReview from "./AddReview";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import UserReviewTile from "./UserReviewTile";

const TrustVerification = ({ user }: { user: UserModel }) => {
  const currentUser = useUser();

  const { data: reviews, mutate: mutateReviews } = useSWR(
    `${user.id}`,
    getUserReviews
  );
  const [isOpenReviews, setIsOpenReviews] = useState(false);

  const currentUserHasUserRole =
    currentUser && currentUser.roles.includes("user");
  const currentUserNotTargetUser = currentUser && user.id !== currentUser.id;

  const userIsAlreadyAdded = () => {
    return (
      currentUser?.id && reviews?.some((r) => r.authorId === currentUser.id)
    );
  };

  const isPossibleToAddReview =
    currentUser &&
    currentUserHasUserRole &&
    currentUserNotTargetUser &&
    !userIsAlreadyAdded();

  const alert = () => {
    if (!currentUser) {
      return (
        <Alert variant="outlined" severity="info" icon={false} sx={{ mt: 1 }}>
          Будь ласка,{" "}
          <Link to={"/sign-in"} component={RouterLink}>
            <strong>авторизуйтеся</strong>
          </Link>{" "}
          для того щоб залишити відгук про волонтера
        </Alert>
      );
    }

    if (currentUserNotTargetUser && !currentUserHasUserRole) {
      return (
        <Alert
          variant="outlined"
          severity="warning"
          icon={false}
          sx={{ mt: 1 }}
        >
          Будь ласка, заповніть ваш{" "}
          <Link to={"/profile/edit"} component={RouterLink}>
            <strong>профіль</strong>
          </Link>{" "}
          для того щоб залишити відгук про волонтера
        </Alert>
      );
    }
    return <></>;
  };

  const onReviewSubmitted = async () => {
    await mutateReviews();
  };

  return (
    <>
      {alert()}
      {isPossibleToAddReview && (
        <>
          <Divider sx={{ my: 1 }} />
          <AddReview user={user} onSubmit={onReviewSubmitted} />
        </>
      )}
      {!!reviews?.length && (
        <>
          <Divider sx={{ my: 1 }} />
          <ListItemButton onClick={() => setIsOpenReviews(!isOpenReviews)}>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText
              primary={`Відгуки про волонтера (${reviews.length})`}
            />
            {isOpenReviews ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpenReviews} timeout="auto" unmountOnExit>
            <List>
              {reviews.map((r, index) => (
                <React.Fragment key={r.id}>
                  <UserReviewTile review={r} />
                  {index !== reviews.length - 1 && <Divider component="li" />}
                </React.Fragment>
              ))}
            </List>
          </Collapse>
        </>
      )}
    </>
  );
};

export default TrustVerification;
