import React, { CSSProperties } from "react";
import { Icon, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";

interface TextWithIconProps {
  icon: OverridableComponent<SvgIconTypeMap>;
  iconAfter?: OverridableComponent<SvgIconTypeMap> | null;
  text: string;
  styles?: CSSProperties;
  button?: JSX.Element | null;
}

export const TextWithIcon = ({
  icon,
  text,
  styles,
  iconAfter,
  button = null,
}: TextWithIconProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        ...styles,
      }}
    >
      <Icon sx={{ mr: 1 }} fontSize="large" component={icon}></Icon>
      <Typography variant="h5">{text}</Typography>
      {iconAfter && (
        <Icon sx={{ ml: 1 }} fontSize="large" component={iconAfter}></Icon>
      )}
      {button && <div style={{ marginLeft: "auto" }}>{button}</div>}
    </div>
  );
};
