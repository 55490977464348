import { ProjectModel, Tag, UserModel } from "../types";
import api from "./server";
import { convertGMTToLocal } from "../helpers";
import { UserReview } from "../types/UserReview";
import { ProjectStatus } from "../types/ProjectStatus";
import { memoizeWithTTL } from "../helpers/memoize";

export const getProjects = memoizeWithTTL(
  async ({
    userId = "",
    tagId = "",
    status = ProjectStatus.Published,
  }: {
    userId?: string | number;
    tagId?: string;
    status?: ProjectStatus;
  }): Promise<ProjectModel[]> => {
    let url = `public/projects`;
    const queryParams = new URLSearchParams();
    if (userId) {
      queryParams.append("user_id", `${userId}`);
    }
    if (tagId) {
      queryParams.append("tag_id", tagId);
    }
    queryParams.append("status", status);
    const query = queryParams.toString();
    if (query) {
      url = `${url}?${query}`;
    }
    const response = await api.get(url);
    return response.data;
  },
  2000
);

export const getUserReviews = async (
  id: number | string
): Promise<UserReview[]> => {
  const response = await api.get(`public/users/${id}/reviews`);
  return response.data;
};

export async function getUser(id: number | string): Promise<UserModel> {
  const response = await api.get(`public/users/${id}`);
  return response.data;
}

export async function getTag(id: number | string): Promise<Tag> {
  const response = await api.get(`public/tags/${id}`);
  return response.data;
}

export async function getProject(id: number): Promise<ProjectModel> {
  const response = await api.get(`public/projects/${id}`);
  return response.data;
}

interface UpdateItem {
  date: string;
  accountId: number;
  value?: number;
}

export async function getProjectUpdates(id: number): Promise<UpdateItem[]> {
  const response = await api.get(`public/projects/${id}/updates`);
  return (response.data as UpdateItem[]).map((item) => {
    return {
      ...item,
      date: convertGMTToLocal(item.date),
    };
  });
}
