import { Box, Chip, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { UserReview } from "../../types/UserReview";
import UserAvatar from "./UserAvatar";
import { formatDatetime } from "../../helpers";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Link as RouterLink } from "react-router-dom";

const UserReviewTile = ({ review }: { review: UserReview }) => {
  const author = review.author;

  return (
    <Stack component="li" direction="row" sx={{ p: 1, mb: 1 }}>
      <Box sx={{ pt: 0.5 }}>
        <UserAvatar user={author} />
      </Box>
      <Stack>
        <Link
          to={`/users/${author.id}`}
          component={RouterLink}
          sx={{
            display: "flex",
            alignItems: "center",
            alignSelf: "start",
            mb: 0.5,
          }}
        >
          <Typography variant="h6" lineHeight={1.25}>
            {`${author.first_name} ${author.last_name}`}
          </Typography>
          {author.is_verified && <VerifiedIcon sx={{ ml: 0.5 }} />}
        </Link>
        <Typography variant="body1">{review.text}</Typography>

        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          {review.isPositive ? (
            <Chip size="small" color="success" label="Позитивний" />
          ) : (
            <Chip size="small" color="error" label="Негативний" />
          )}
          <Chip
            size="small"
            label={formatDatetime(new Date(review.created_at))}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserReviewTile;
