import Register from "../components/Register";

const SignUpPage = () => {
  return (
    <div>
      <Register></Register>
    </div>
  );
};

export default SignUpPage;
