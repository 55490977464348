import React, { ChangeEvent, FormEvent, useState } from "react";
import { Account, AccountType } from "../../types";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MoneyTextField from "../MoneyTextField";

interface Props {
  account: Account | null;
  onSubmit: (account: Account) => void;
  onCancel: () => void;
}

export const AccountForm = ({ account, onSubmit, onCancel }: Props) => {
  const [title, setTitle] = useState(account?.title || "");
  const [type, setType] = useState(account?.type || "monobank-jar");
  const [requisites, setRequisites] = useState(account?.requisites || "");
  const [amount, setAmount] = useState(account?.amount || 0);
  const [errors, setErrors] = useState<any>({});

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleRequisitesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRequisites(event.target.value);
    setErrors({});
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errors: any = {};
    if (type === "monobank-jar") {
      const pattern = /^https:\/\/send\.monobank\.ua\/jar\/\w+$/;
      if (!requisites.match(pattern)) {
        errors.requisites = "Invalid requisites for Monobank jar";
      }
    } else if (type === "bankcard") {
      const pattern = /^\d{16}$/;
      if (!requisites.match(pattern)) {
        errors.requisites = "Invalid credit card number";
      }
    } else {
      if (!requisites && type !== "cash") {
        errors.requisites = "Requisites should not be empty";
      }
    }

    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    const updatedAccount: Account = {
      ...account,
      title,
      type,
      requisites,
      amount,
    };

    onSubmit(updatedAccount);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        margin="normal"
        fullWidth
        required
        label="Title"
        value={title}
        onChange={handleTitleChange}
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Type</InputLabel>
        <Select
          fullWidth
          required
          label="Type"
          value={type}
          onChange={(e) => {
            const selectedType = e.target.value as AccountType;
            setType(selectedType);
          }}
        >
          <MenuItem value={"monobank-jar"}>Monobank Jar</MenuItem>
          <MenuItem value={"bankcard"}>Bankcard</MenuItem>
          <MenuItem value={"paypal"}>Paypal</MenuItem>
          <MenuItem value={"cash"}>Cash</MenuItem>
          <MenuItem value={"bank"}>Bank</MenuItem>
        </Select>
      </FormControl>

      <TextField
        margin="normal"
        fullWidth
        multiline
        required={type !== "cash"}
        label="Requisites"
        value={requisites}
        error={Boolean(errors.requisites)}
        onChange={handleRequisitesChange}
        helperText={
          errors.requisites ||
          (type === "monobank-jar"
            ? "Format: https://send.monobank.ua/jar/<jar id>"
            : type === "bankcard"
            ? "Enter a valid 16-digit credit card number"
            : "")
        }
      />

      <MoneyTextField
        label="Amount"
        name="amount"
        value={amount}
        type="number"
        onChange={(value) => {
          setAmount(value || 0);
        }}
        required
        fullWidth
        margin="normal"
      />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
        <Button onClick={onCancel} sx={{ ml: 1 }}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
