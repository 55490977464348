import * as React from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { register as registerRequest } from "../api";
import { useAuth } from "../hooks/useAuth";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CardActions, CardContent, Link } from "@mui/material";
import BoxCard from "./BoxCard";

type RegisterProps = {};

const Register = (props: RegisterProps) => {
  const { token, login } = useAuth();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    passwordConfirmation: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage("");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await registerRequest({
        username: formData.email,
        password: formData.password,
      });
      login(response.access_token);
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred while registering. Please try again.");
    }
  };

  if (token) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <BoxCard title="Register">
      <CardContent>
        {errorMessage && (
          <Typography color="error" sx={{ py: 2 }}>
            {errorMessage}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              fullWidth
              type="password"
              name="passwordConfirmation"
              value={formData.passwordConfirmation}
              onChange={handleInputChange}
              required
            />

            <Button variant="contained" type="submit" fullWidth>
              Register
            </Button>
          </Stack>
        </form>
      </CardContent>
      <CardActions sx={{ px: 2 }} disableSpacing>
        <Typography variant="body2" color="text.secondary">
          Already have an account? <Link href="/sign-in">Sign In</Link>
        </Typography>
      </CardActions>
    </BoxCard>
  );
};

export default Register;
