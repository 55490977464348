import {
  Project,
  ProjectUpdateFormByVolunteer,
  ProjectUpdateModel,
  User,
} from "../types";
import api from "./server";
import { CreateUserReviewDto, UserReview } from "../types/UserReview";
import { CreateProjectDto } from "../types/CreateProject";
import { UploadFile } from "../types/File";

export async function updateProfile(user: User): Promise<User> {
  const response = await api.put(`/protected/users/profile`, user);
  return response.data;
}

export async function updateAvatar(file: UploadFile): Promise<User> {
  const formData = new FormData();
  const blob = await fetch(file.blob).then((res) => res.blob());

  formData.append("avatar", blob, file.name);

  const response = await api.post(`/protected/users/profile/avatar`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function createUserReview(
  dto: CreateUserReviewDto
): Promise<UserReview> {
  const response = await api.post(`/protected/users/review`, dto);
  return response.data;
}

export async function createProject(dto: CreateProjectDto): Promise<Project> {
  const response = await api.post(`/protected/projects`, dto);
  return response.data;
}

export async function createProjectUpdate(
  id: string,
  dto: ProjectUpdateFormByVolunteer
): Promise<Project> {
  const response = await api.post(`/protected/projects/${id}/update`, dto);
  return response.data;
}

export async function editProjectUpdate(
  update: ProjectUpdateModel
): Promise<Project> {
  const response = await api.put(
    `/protected/projects/${update.projectId}/update/${update.id}`,
    update
  );
  return response.data;
}

export async function getPresignedUrl(filename: string): Promise<string> {
  const presignedUrlResponse = await api.post("/generate-presigned-url", {
    filename,
  });
  return presignedUrlResponse.data as string;
}

export async function getUploadUrl(): Promise<{
  uploadURL: string;
  id: string;
}> {
  const response = await api.post(`/upload-url`);
  return response.data;
}
