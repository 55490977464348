import React, { ChangeEvent, FormEvent, useState } from "react";
import { ProjectUpdate } from "../../types";
import { ImageData } from "../../types/File";
import { Button, FormControl, InputLabel, TextField } from "@mui/material";
import { convertGMTToLocal } from "../../helpers";
import "./ImageFileUpload.css";
import { ImageFileUpload } from "./ImageFileUpload";

interface Props {
  projectUpdate: ProjectUpdate | null;
  onSubmit: (projectUpdate: ProjectUpdate) => void;
  onCancel: () => void;
}

export const ProjectUpdateForm = ({
  projectUpdate,
  onSubmit,
  onCancel,
}: Props) => {
  const [text, setText] = useState(projectUpdate?.text || "");
  const [files, setFiles] = useState<ImageData[]>([]);
  const [createdAt, setCreatedAt] = useState(
    new Date(
      projectUpdate?.created_at
        ? convertGMTToLocal(projectUpdate.created_at) // convert GMT to local
        : convertGMTToLocal(new Date())
    )
      .toISOString()
      .slice(0, -5)
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const updatedProjectUpdate: ProjectUpdate = {
      ...projectUpdate,
      text,
      files,
      created_at: new Date(createdAt).toISOString(), // convert local time back to GMT
    };
    onSubmit(updatedProjectUpdate);
  };

  const onChange = (images: ImageData[]) => {
    setFiles(images);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        margin="normal"
        fullWidth
        multiline
        required
        label="Text"
        value={text}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setText(event.target.value)
        }
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Images</InputLabel>
        <ImageFileUpload onChange={onChange} files={files}></ImageFileUpload>
      </FormControl>

      <TextField
        margin="normal"
        fullWidth
        required
        label="Created At"
        type="datetime-local"
        value={createdAt}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setCreatedAt(event.target.value)
        }
      />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
        <Button onClick={onCancel} sx={{ ml: 1 }}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
